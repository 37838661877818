@import '../../../scss/variables';
@import '../../../scss/mixins';

.change-timeslot {
    display: flex;
    justify-content: space-between;

    @include respond-max-width-breakpoint(medium) {
        display: block;
    }

    &__time-picker {
        width: 80%;

        @include respond-max-width-breakpoint(medium) {
            width: 100%;
            margin-bottom: 5rem;
        }
    }
    &__sidebar {
        width: 250px;
        max-width: 250px;
        background-color: rgba(227, 0, 49, 0.04);
        padding: 2rem 0 0;
        padding: 2rem 0.5rem 0.5rem;
        border-radius: 40px;
        position: sticky;
        top: 4.75rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: fit-content;
        gap: 1rem;

        @include respond-max-width-breakpoint(medium) {
            display: flex;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0;
            top: auto;
            z-index: 1;
            padding: 0.5rem 1rem;
            border-radius: 1.5rem 1.5rem 0 0;
            background: #fef5f7;
            width: auto;
            max-width: none;

            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            button {
                font-size: 1rem;
                padding: 1rem 2rem;
            }
        }
    }

    &__sidebar-content {
        padding: 0 1rem;

        svg {
            color: #e30031;
        }

        @include respond-max-width-breakpoint(medium) {
            font-size: 1rem;
            h2,
            svg {
                display: none;
            }
        }
    }

    &__sidebar-item {
        font-size: 0.875rem;
        text-transform: capitalize;
        padding: 0.5rem 0;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;

        @include respond-max-width-breakpoint(medium) {
            font-size: 1rem;
        }
    }

    &__sidebar-footer {
        display: flex;
        justify-content: center;
        .timeslot__spinner {
            padding: 1rem;

            & > div {
                width: 32px;
                height: 32px;
            }

            @include respond-max-width-breakpoint(medium) {
                padding: 0;
                width: 28px;
                height: 28px;
                display: flex;
                justify-content: center;
                margin-right: 36px;
                margin: 11px 36px 11px 0;
            }
        }
    }
}
