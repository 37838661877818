@import '../../../scss/variables';

h3 {
    font-size: 1.25rem;
    line-height: 1.625rem;
    margin-top: 14px;
    color: $primaryTextColor;
}
.leaflet-container a {
    text-decoration: none;
    color: white;
    height: 48px;
    font-size: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pop-up {
    hyphens: auto;
    .leaflet-popup-content-wrapper {
        border-radius: 32px;
        box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
        color: $primaryTextColor;
    }
    .leaflet-popup-content {
        margin: 0;

        // Fix to allow popup to fit when using browser zoom
        @media (max-height: 31.25rem) {
            max-height: 300px;
            overflow: auto;
        }
    }
    .popup {
        &-header {
            padding: 24px 24px 0 24px;
        }
        &-main {
            padding: 0 24px 24px;
        }
        &-extra {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            padding: 1rem 1.5rem;
        }
        &-button {
            padding: 1rem 1.5rem 1.5rem;
        }
    }
}
.popup-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    align-items: center;
}
div.popup-button {
    > a > Button {
        font-size: 1rem;
        width: 100%;
        padding: 13px 45px;
    }
}
.leaflet-popup-content {
    font-size: 1rem;
    font-family: 'RubikVariable';

    .popup-main {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        &__header {
            margin-top: 1rem;
            margin-bottom: 0;
        }

        &__text-container {
            display: flex;
            flex-wrap: wrap;
            row-gap: 0.25rem;

            &--flex-column {
                flex-direction: column;
            }

            &__text {
                text-wrap: balance;
                font-size: 1.125rem;
                line-height: 1.625rem;
            }
        }
    }
}

.leaflet-popup-content p {
    margin: 0;
    font-size: 1rem;
    line-height: 1.5rem;
}

.leaflet-popup-tip {
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    background: url('../../icons/indicator.svg') no-repeat;
    width: 34px;
    height: 20px;
    padding: 0;
    margin: 0;
    pointer-events: auto;
    transform: rotate(0deg);
}
.leaflet-popup-tip-container {
    width: 34px;
    height: 20px;
    position: absolute;
    left: 50%;
    margin-top: -1px;
    margin-left: -17px;
    overflow: hidden;
    pointer-events: none;
}
.leaflet-popup {
    margin-bottom: 23px;
}
.info {
    margin-bottom: 16px;
}
.close-button {
    height: 40px;
    width: 40px;
    background-color: rgba(227, 0, 49, 0.04);
    color: $primaryAccentColor;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        background-color: rgba(227, 0, 49, 0.1);
    }
    &:focus-visible {
        outline: none;
        box-shadow: 0 0 0 2px #121212;
    }
}
