@import '../../../scss/mixins';

.map_modal {
    position: absolute;
    box-sizing: border-box;
    width: 343px;
    background: #fff;
    border-radius: 32px;
    top: 0;
    bottom: 0;
    margin: 16px;
    z-index: 1;
    overflow: hidden;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);

    &__content {
        border: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 24px;
        display: flex;
        flex-direction: column;

        &--borderless {
            border-top: none;
            padding-top: 0;
        }

        &:first-child {
            border: none;
        }
        &:nth-child(2):not(.search_modal__skeleton) {
            padding: 12px 24px 24px;
            border: none;
        }
    }

    &__header {
        position: sticky;
        top: 0;
        background: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 32px 24px 12px;
        border-radius: 32px 32px 0 0;

        h1 {
            margin: 0;
            flex: 1;
        }
    }

    &__scroll {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-y: auto;
    }

    &--dark {
        background: #121212;
        color: #fff;
        box-shadow: none;
    }

    @include respond-max-width-breakpoint(small) {
        margin: 0;
        width: 100vw;
    }
}
