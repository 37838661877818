@use 'sass:color';
@import '../../../scss/variables';

.checkbox-container {
    margin-bottom: 2px;
    position: relative;

    &.disabled {
        opacity: 0.3;
        pointer-events: none;
    }

    .checkbox_box {
        border-radius: 16px;
        line-height: 1.5rem;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        border: 3px solid transparent;
        user-select: none;
        cursor: pointer;

        .checkbox__label {
            max-width: 80%;
            overflow-wrap: break-word;
            word-break: break-word;
            hyphens: auto;
        }

        .checkbox {
            width: 24px;
            height: 24px;
            border: 1px solid rgba(0, 0, 0, 0.25);
            border-radius: 6px;
            margin-top: 3px;
            display: flex;
            align-items: center;
            justify-content: center;

            &--error {
                background-color: #fef5f7;
                border-color: $primaryColor;
            }
        }
    }

    input[type='checkbox']:focus-visible + .checkbox_box {
        outline: none;
        border-color: transparent;
        box-shadow: 0 0 0 3px #121212;
    }

    input[type='checkbox']:checked + .checkbox_box {
        .checkbox {
            border-color: transparent;
            background-color: #e30031;
        }
    }

    // Hide the actual checkbox
    input[type='checkbox'] {
        clip: rect(0 0 0 0);
        clip-path: inset(100%);
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        height: 24px;
        width: 24px;
        top: 6px;
        left: 3px;
    }
}
