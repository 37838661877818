@import '../../../../../scss/mixins';

.consent-completed {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        max-width: 30rem;
        margin-top: 7.5rem;

        @include respond-max-width-breakpoint(medium) {
            margin-top: 5rem;
        }
    }

    .header {
        margin-top: 2rem;
        font-size: 2.5rem;
    }
    .subheader {
        font-size: 1.125rem;
        margin-bottom: 2rem;
    }
    .link-section {
        margin-top: 2rem;
    }
}
