@import '../../../scss/mixins';
@import '../../../scss/variables';

.search-header-container {
    padding: 12px 0 8px;
    button {
        margin-right: -8px;
        display: none;

        @include respond-max-width-breakpoint(small) {
            display: flex;
        }
    }
}

.search_box_form {
    position: relative;
    background: #fff;

    .search_box {
        display: flex;
        align-items: center;
        background: rgba(0, 0, 0, 0.04);
        border-radius: 30px;
        padding: 12px 51px 12px 45px;
        position: relative;
        z-index: 1;
        transition: margin 0.2s ease, background-color 0.2s ease, box-shadow 0.2s ease;

        &.has-focus {
            margin-right: -16px;
            margin-left: -16px;
        }

        > svg {
            position: absolute;
            left: 16px;
            top: 16px;
            height: 16px;
        }

        &--no-results {
            background: #fff;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
            top: 0;
            border-radius: 24px;
        }

        &:hover,
        &:focus-within {
            background: #fff;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        }

        &__input-container {
            position: sticky;
            width: 100%;
            top: 0;
            height: fit-content;
            z-index: 1;
            padding: 5px 0 0 0;
            background-color: #fff;
        }

        &__clear-button {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0.875rem;
            right: 1rem;
            height: 1.5rem;
            width: 1.5rem;
            opacity: 0.7;
            transition: opacity 0.2s ease;
            border-radius: 1rem;
            border: none;

            &:focus-visible {
                opacity: 1;
                box-shadow: 0 0 0 2px #121212;
                outline: none;
            }
        }

        input[type='text'] {
            outline: none;
            font-size: 1rem;
            width: 100%;
            line-height: 1.5rem;
            font-style: normal;
            border: none;
            background: transparent;
            display: flex;
            align-items: center;
        }
    }

    .svg-white {
        color: #e30031;
    }
}
