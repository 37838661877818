@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.travel-modal {
    @media (max-width: 15.625rem) {
        word-break: break-word;
        hyphens: auto;
    }

    &__departure-section {
        margin-top: 2rem;
    }

    &__travel-section {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin-top: 0.5rem;
    }

    &__travel-item {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    &__travel-item-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0.5rem 2rem;
    }

    &__select-container {
        display: flex;
        position: relative;

        &--with-value {
            .travel-modal__select {
                padding: 2.5rem 2rem 1.0625rem;
            }

            .travel-modal__select-label {
                font-size: 0.875rem;
                line-height: 1.25rem;
                display: block;
                position: absolute;
                top: 1.09375rem;
                left: 2rem;
                pointer-events: none;
            }
        }
    }

    &__remove-destination-link {
        margin: 1rem 2rem 0 0;
        text-align: right;
    }

    &__select {
        display: flex;
        flex: 1;
        font-family: 'Rubik', sans-serif;
        font-size: 1.25rem;
        line-height: 125%;
        color: $primaryTextColor;
        box-shadow: 0 0 0 1px rgba(18, 18, 18, 0.15);
        border-radius: 2.5rem;
        padding: 1.7815rem 2rem;
        background-color: transparent;
        background-image: url('../../../icons/icon_dropdown_32.svg');
        background-repeat: no-repeat;
        background-position: calc(100% - 1.5rem) center;
        appearance: none;
        border: none;

        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;

        &:disabled {
            opacity: 0.3;
            &:hover {
                cursor: auto;
                background-color: transparent;
            }
        }

        &:hover {
            cursor: pointer;
            background-color: rgba($primaryAccentColor, 0.1);
        }

        &:focus-visible {
            outline: #121212 auto 3px;
        }

        @include respond-max-width-breakpoint(medium) {
            width: 100%;
            font-size: 1.125rem;
        }
    }

    &__travel-section-footer {
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
        margin-top: 2.5rem;
    }

    &__add-travel-link {
        display: inline-flex;
        align-items: center;
        gap: 1rem;
        font-size: 1.125rem;

        @media (max-width: 15.625rem) {
            word-break: break-word;
            hyphens: auto;
        }
    }

    &__add-travel-button {
        gap: 1rem;
        font-size: 1.125rem;

        &:focus-visible {
            outline: 2px solid #121212;
        }
    }
}
