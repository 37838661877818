@import '../../../../../scss/variables';
@import '../../../../../scss/mixins';

.people-form {
    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__name-container {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        margin-bottom: 1rem;

        &--name-input {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        @include respond-max-width-breakpoint(medium) {
            flex-direction: column;
            gap: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }

    &__remove-button {
        padding: 0.5rem;
        margin-right: -0.5rem;
        margin-top: -0.5rem;
        font-size: 1.125rem;
        line-height: 1.375rem;
        display: flex;
        align-self: flex-start;
    }

    &__footer {
        margin-top: 3.125rem;
        display: flex;
        justify-content: flex-end;

        @include respond-max-width-breakpoint(medium) {
            margin-top: 2rem;
            flex-direction: column;
        }
    }

    &__patient-form {
        @include respond-max-width-breakpoint(medium) {
            margin-top: 2rem;
        }
    }
}
