.time-slot-column--skeleton {
    margin-top: 24px;
    .time-slot-column__day--skeleton {
        .time-slot-column__day__date {
            height: 2rem;
            width: 6rem;
            background: rgba(#121212, 0.05);
            border-radius: 60px;
            display: inline-block;
            position: relative;
            overflow: hidden;
        }
        .time-slot-column__day__day {
            height: 2rem;
            width: 3rem;
            background: rgba(#121212, 0.05);
            border-radius: 60px;
            display: inline-block;
            position: relative;
            overflow: hidden;
        }
    }

    .time-slot-column__slot--skeleton {
        height: 58px;
        background: rgba(#121212, 0.05);
        border-radius: 60px;
        display: inline-block;
        position: relative;
        overflow: hidden;

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            transform: translateX(-100%);
            background-image: linear-gradient(
                90deg,
                rgba(#121212, 0) 0,
                rgba(#121212, 0.02) 20%,
                rgba(#121212, 0.05) 60%,
                rgba(#121212, 0)
            );
            animation: shimmer 2s infinite;
            content: '';
        }

        @keyframes shimmer {
            100% {
                transform: translateX(100%);
            }
        }
    }
}
