@import 'src/scss/variables.scss';
@import 'src/scss/mixins/breakpoints.scss';

$error-label-bottom-margin: calc(0.875rem * 1.5 + 0.25rem);

.select-container {
    font-family: 'RubikVariable';

    &.invalid {
        margin-bottom: $error-label-bottom-margin;
    }

    &__placeholder-label {
        pointer-events: none;
        position: absolute;
        top: 17.5px;
        left: 33px;
        font-size: 0.875rem;
        line-height: 20px;
    }

    &__error-label {
        display: inline-block;
        font-size: 0.875rem;
        line-height: 1.5;
        color: $primaryColor;
        margin: 0.5rem 33px 0;
    }

    &__select {
        width: 100%;
        padding: 27.5px 64px 27.5px 32px;
        font-family: inherit;
        font-size: 1.125rem;
        line-height: 1.25;
        color: $black;
        background-color: $primaryBackgroundColor;
        border: 1px solid #d4d4d4;
        border-radius: 40px;
        appearance: none;
        scroll-margin: 2rem;

        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;

        &--has-selection {
            color: $primaryTextColor;
            padding-top: 37.5px;
            padding-bottom: 17.5px;
        }

        &--small {
            color: $primaryColor;
            font-weight: 600;
            padding: 10px 36px 10px 20px;
        }

        &.invalid {
            box-shadow: 0 0 0 1px $primaryColor inset;
            border: 1px solid $primaryColor;
        }

        &:disabled {
            opacity: 0.3;
            &:hover {
                cursor: auto;
                background-color: transparent;
            }
        }

        &:hover {
            cursor: pointer;
            background-color: #fbdce3;
        }

        &:focus-visible {
            outline: #121212 auto 1px;
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        right: 1.5rem;
        transform: translateY(-50%);
        pointer-events: none;

        &__error {
            top: 2.48rem;
            transform: translateY(-50%);
        }

        &--small {
            right: 0.75rem;
            width: 24px;
        }
    }
}
