.remove-modal {
    &__remove-text {
        color: #616161;
        margin-bottom: 1rem;
    }
    &__remove-button {
        width: 100%;
        margin-top: 1rem;
    }

    &__loader-container {
        display: grid;
        place-items: center;

        &__spinner {
            width: 2.5rem;
        }
    }
}
