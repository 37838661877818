@import 'src/scss/variables';
@import 'src/scss/mixins';

.vaccination-box {
    display: flex;
    flex-direction: column;
    border-radius: 2.5rem;
    border: 1px solid rgba(18, 18, 18, 0.15);
    cursor: pointer;

    @media (hover: hover) {
        &:hover {
            background: #e300311a;
        }
    }

    &--selected {
        background: linear-gradient(0deg, rgba(227, 0, 49, 0.04), rgba(227, 0, 49, 0.04));
        outline: 2px solid $primaryAccentColor;
    }

    &:focus {
        outline: 4px solid $primaryAccentColor;
    }

    &__content {
        padding: 1.5rem 1.5rem;
        display: flex;

        &__left {
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: space-between;

            &__top-row {
                display: flex;
                justify-content: space-between;
                margin-bottom: 0.125rem;
                flex-direction: column-reverse;
                align-items: flex-start;

                &__header {
                    margin-bottom: 0;
                    font-size: 1.5rem;
                }

                &__date {
                    font-size: 0.875rem;
                    margin-bottom: 0.125rem;
                    @include respond-min-width-breakpoint(compact) {
                        display: none;
                    }
                    @include respond-min-width-breakpoint(medium) {
                        display: flex;
                    }
                    @include respond-min-width-breakpoint(large) {
                        display: none;
                    }
                }
            }
            &__bottom-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 1rem;

                &__vaccine-left-column {
                    display: flex;
                    flex-direction: column;
                    font-size: 1.25rem;
                    line-height: 1.625rem;
                    letter-spacing: -0.01rem;

                    &__label {
                        font-size: 1rem;

                        &:not(:last-of-type) {
                            margin-bottom: 0.125rem;
                        }
                    }
                }
            }
        }

        &__right {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 2rem;

            @include respond-max-width-breakpoint(x-small) {
                padding-left: 0;
            }

            &__date {
                display: none;
                @include respond-min-width-breakpoint(compact) {
                    display: flex;
                    margin-right: 1rem;
                }
                @include respond-min-width-breakpoint(medium) {
                    display: none;
                }
                @include respond-min-width-breakpoint(large) {
                    display: flex;
                    margin-right: 1rem;
                }
            }

            &__icon {
                transform: rotate(180deg);
                color: $primaryColor;
                @include respond-max-width-breakpoint(x-small) {
                    display: none;
                }
            }
        }
    }

    &__notification {
        padding: 1.5rem 1.5rem;
        border-top: 1px solid rgba(18, 18, 18, 0.15);
        display: flex;

        &__left {
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: space-between;
        }

        &__right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 2rem;
            @include respond-max-width-breakpoint(x-small) {
                display: none;
            }
        }
    }
}
.text-secondary {
    // added locally to override global styles // TODO: remove when typography is refactored
    color: #585858;
}
