@import '../../../scss/variables';
@import '../../../scss/mixins';

.map_control {
    font-weight: 500;
    box-sizing: border-box;
    padding: 8px;
    height: 40px;
    border-radius: 12px;
    background: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    box-shadow: 0px 10px 20px rgb(0 0 0 / 10%);

    @media (hover: hover) {
        &:hover {
            background: darken(#fff, 10);
        }
    }

    &:active {
        background: darken(#fff, 1);
    }

    &:focus-visible {
        outline: none;

        &::before {
            content: '';
            position: absolute;
            top: -2px;
            right: -2px;
            bottom: -2px;
            left: -2px;
            border: 3px solid #121212;
            border-radius: 12px;
            z-index: 400;
        }
    }

    @supports not selector(:focus-visible) {
        &:focus {
            /* Fallback for browsers without :focus-visible support */
            outline: 3px solid #121212;
        }
    }

    &__label {
        font-size: 0.87rem;
        margin-left: 12px;
        font-weight: 500;
        font-variation-settings: 'wght' 500;
    }

    &__primary {
        color: $primaryColor;

        @media (hover: hover) {
            &:hover {
                background: darken(#fff, 5);
            }
        }

        &:active {
            background: darken(#fff, 5);
        }
        &:focus {
            border: none;
        }
    }

    &__secondary {
        color: $secondaryColor;
    }

    &__tertiary {
        color: $tertiaryColor;
    }

    &--filled {
        background: $primaryColor;

        @media (hover: hover) {
            &:hover {
                background: darken($primaryColor, 10);
            }
        }

        svg {
            fill: #fff;
            & > * {
                fill: #fff;
                stroke: #fff;
            }
        }
    }

    &--disabled {
        cursor: pointer;
        background: #fff;
        svg {
            fill: #bdbdbd;
            & > * {
                fill: #bdbdbd;
                stroke: #bdbdbd;
            }
        }
    }

    &--labeled {
        padding: 8px 16px;
    }
}

.map_control_group {
    display: flex;
    flex-direction: row;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    margin-right: 24px;

    .map_control {
        border-right: 1px solid #eee;
        border-bottom: none;
        box-shadow: none;

        &:first-child {
            border-radius: 12px 0 0 12px;
            &:focus-visible {
                &::before {
                    border-radius: 12px 0 0 12px;
                }
            }
        }

        &:last-child {
            border-radius: 0 12px 12px 0;
            border-right: none;

            &:focus-visible {
                &::before {
                    border-radius: 0 12px 12px 0;
                }
            }
        }
    }
}
