@import 'src/scss/variables';

.t-black {
    color: $black;
}
.t-red {
    color: $red;
}
.t-light-pink {
    color: $light-pink;
}
.t-pink {
    color: $pink;
}
.t-gray300 {
    color: $gray300;
}
.t-gray600 {
    color: $gray600;
}
.t-gray700 {
    color: $gray700;
}
