@import '../../../scss/mixins';
@import '../../../scss/variables';

.history-navigation-container {
    display: grid;
    column-gap: 2rem;
    max-width: 1440px;
    grid-template-columns: repeat(12, 1fr);

    &__left-column {
        grid-column-start: 1;
        grid-column-end: 7;
    }
    &__vaccine-details-container {
        grid-column-start: 7;
        grid-column-end: 13;

        &--sticky {
            position: sticky;
            top: 2rem;
        }
    }

    &__vaccine-details {
        &__modal {
            position: absolute;
            padding: 2rem 1rem 0 1rem;
            top: 0;
            left: 0;
            right: 0;
            background: white;
            z-index: 2;

            &__navbar {
                position: fixed;
                left: 0;
                top: 0;
                right: 0;
                background: white;
                width: 100%;
                display: grid;
                grid-template-columns: auto 1fr;
                align-items: center;
                padding: 0 1rem;
                min-height: 60px;
                z-index: 1;

                &__button {
                    margin-left: 1rem;
                    color: $primaryColor;
                    text-align: end;
                    font-size: 1rem;
                    font-variation-settings: 'wght' 400;
                }
            }
        }
    }

    @include respond-max-width-breakpoint(medium) {
        grid-template-columns: repeat(8, 1fr);
        &__left-column {
            grid-column-start: 1;
            grid-column-end: 9;
        }
    }

    @include respond-max-width-breakpoint(compact) {
        grid-template-columns: repeat(4, 1fr);
        &__left-column {
            grid-column-start: 1;
            grid-column-end: 5;
        }
    }

    &__vaccine-list {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    &__header {
        font-size: 2.5rem;
        font-variation-settings: 'wght' 700;
        line-height: 1.15;
        grid-column: 1 / -1;
    }

    &__relations {
        display: grid;
        grid-auto-flow: column;
        justify-self: flex-start;
        grid-column: 1 / -1;
        gap: 1rem;

        @include respond-max-width-breakpoint(x-small) {
            // to handle zoom 200%
            gap: 0;
            grid-auto-flow: row;
            justify-items: flex-start;
        }

        &__item {
            color: $gray600;
            margin-top: 1rem;
            text-align: left;
            font-variation-settings: 'wght' 700;

            &--selected {
                width: fit-content;
                position: relative;
                color: $black;
                margin-top: 1rem;
                font-variation-settings: 'wght' 700;

                &::after {
                    grid-area: left-column;
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -0.5rem;
                    width: 100%;
                    height: 0.25rem;
                    background-color: $red;
                    border-radius: 2px;
                    box-shadow: 0 40px 11px rgba(102, 0, 20, 0.01), 0 25px 10px rgba(102, 0, 20, 0.04),
                        0 14px 9px rgba(102, 0, 20, 0.15), 0 6px 6px rgba(102, 0, 20, 0.26),
                        0 2px 4px rgba(102, 0, 20, 0.29), 0 0 0 rgba(102, 0, 20, 0.3);
                }
            }
        }
    }

    &__add-vaccination {
        display: flex;
        align-items: center;
        margin-top: 3rem;
        margin-bottom: 1.5rem;
        grid-column: 1 / -1;

        &__button {
            display: inline-flex;
            padding: 0.5rem;
        }

        &__label {
            font-size: 1.125rem;
            font-family: 'RubikVariable';
            font-variation-settings: 'wght' 600;
            margin-left: 1rem;
            cursor: pointer;
        }
    }

    &__spinner {
        padding-top: 5rem;
        grid-column: 1 / -1;
        justify-self: center;
        align-self: center;
        width: 2.5rem;
    }
}
