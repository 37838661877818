@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.react-datepicker {
    font-family: 'Rubik';
    font-size: 1rem;
    background-color: #fff;
    color: #121212;
    border: none;
    display: inline-block;
    position: relative;

    border-radius: 2rem;
    padding: 1rem;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);

    &__triangle {
        &::before {
            border-bottom-color: #fff;
        }
    }

    &__navigation {
        top: 1rem;
        border-radius: 50%;

        &--previous {
            left: 2rem;
        }

        &--next {
            right: 2rem;
        }

        &:hover {
            background: rgba(227, 0, 49, 0.1);
            .react-datepicker__navigation-icon::before {
                border-color: $primaryAccentColor;
            }
        }
    }

    &__navigation-icon {
        // top: 2px;
        &::before {
            top: 4px;
            border-color: $primaryAccentColor;
        }
    }

    &__header {
        background: #fff;
        border-bottom: none;
    }
    &__current-month {
        margin-bottom: 1rem;
    }

    &__today-button {
        background: #fff;
        border: none;
        border-radius: 0 0 0.3rem 0.3rem;
    }

    &__month {
        margin: 0;
    }

    &__week {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__day-names {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    &__day {
        min-width: 44px;
        min-height: 44px;
        border-radius: 50%;

        text-align: center;
        display: grid;
        align-items: center;

        &:hover {
            border-radius: 50%;
            color: #121212;
            background: rgba(227, 0, 49, 0.1);
        }

        &--selected {
            background: $primaryAccentColor;
            color: #fff;

            &:hover {
                background: $primaryAccentColor;
                color: #fff;
            }
        }

        &--keyboard-selected {
            background: transparent;
            &:hover {
                background: rgba(227, 0, 49, 0.1);
            }
        }

        &--outside-month {
            opacity: 0;
        }

        &:focus-visible {
            outline: 3px solid #121212;
        }
    }
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
    &::before {
        border-bottom-color: #121212;
    }
    &::after {
        border-bottom-color: #fff;
    }
}
