.booking-information {
    width: 100%;

    .stepindicator__list {
        padding: 0 0 40px;
    }
    .stepindicator__list__item {
        font-family: 'RubikVariable';
        font-variation-settings: 'wght' 500;
        font-size: 1rem;
        padding: 0.5rem 40px;
        gap: 1.5rem;

        .people {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            font-family: 'RubikVariable';
            font-variation-settings: 'wght' 500;
            > .name {
                display: block;
                font-family: 'RubikVariable';
                font-variation-settings: 'wght' 500;
            }
        }
        span {
            display: contents;
            font-variation-settings: 'wght' 400;
            line-height: 1.5rem;
            margin-right: 1rem;
        }
    }
    .aside-padding {
        font-size: 1.5rem;
        padding: 10px 40px 0.5rem;
        > span {
            font-family: 'RubikVariable';
            font-variation-settings: 'wght' 400;
            font-size: 1.5rem;
        }
    }
    .stepindicator__list__item > svg {
        align-self: flex-start;
        margin-top: 4px;
    }
    .capitalize {
        text-transform: capitalize;
    }
}
