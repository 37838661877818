@import '../../scss/variables';
@import '../../scss/mixins';

.container {
    display: grid;
    grid-template-columns: 4% 70% 22% 4%;
    grid-template-rows: 60px auto auto;
    grid-template-areas:
        '. header header .'
        '. main aside .'
        '. footer footer .';
    width: 100%;
    word-break: break-word;
    hyphens: auto;

    &.initial {
        grid-template-columns: 4% auto minmax(auto, 350px) 4%;
    }
    &.confirm,
    &.completed {
        grid-template-columns: 4% minmax(450px, auto) minmax(250px, 500px) 4%;
    }

    @include respond-max-width-breakpoint(medium) {
        display: flex;
        padding: 0 1rem;

        &.timeSelect {
            flex: 1;
        }
    }

    .header-nav {
        grid-area: header;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 2;
        height: 60px;
        padding: 0 1rem;
        margin: 0 -1rem;

        &.row-reverse {
            flex-direction: row-reverse;
        }

        &__back-button {
            svg {
                transform: translateY(2px);
            }
        }

        &__links {
            display: flex;
            flex-direction: row;
            gap: 2rem;
        }

        .region-name {
            font-size: 1rem;
            line-height: 1rem;
            font-variation-settings: 'wght' 400;
            color: $primaryTextColor;
            margin: 0;
        }

        @include respond-max-width-breakpoint(medium) {
            padding: 1.125rem 1rem;
            margin: 0 -1rem;
        }
    }
    .main-content,
    .aside-content {
        padding: 2rem 0 6rem;
        @include respond-max-width-breakpoint(medium) {
            padding: 32px 0;
            width: 100%;
        }
    }
    .main-content {
        grid-area: main;
        max-width: 640px;
        margin-right: 2rem;
        @include respond-max-width-breakpoint(medium) {
            max-width: 100%;
        }

        &--error {
            .main-content__content {
                font-size: 1.125rem;
                max-width: 440px;
            }
        }
    }
    .aside-content {
        grid-area: aside;
        @include respond-max-width-breakpoint(medium) {
            min-width: auto;
        }
    }
    @include respond-max-width-breakpoint(medium) {
        flex-direction: column;
    }
    .primary {
        color: $primaryAccentColor;
        font-variation-settings: 'wght' 400;
        font-size: 1rem;
        display: flex;
        align-items: center;
        > svg {
            margin-right: 0.5rem;
        }

        &:focus-visible {
            outline: #121212 auto 3px;
        }
    }

    a:focus-visible {
        outline: #121212 auto 3px;
    }

    &__footer {
        *:focus-visible {
            outline: #121212 auto 1px;
        }
        grid-area: footer;
        padding: 100px 0;
        margin-top: 100px;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid rgba(#121212, 0.15);
        a {
            margin-left: 2rem;
            &:first-child {
                margin-left: 0;
            }
        }
        img {
            height: 40px;
        }
        &__links p {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            max-width: 400px;
            width: auto;
            padding: 0 32px;

            a {
                margin: 0;
                margin-bottom: 1rem;
            }

            @include respond-max-width-breakpoint(medium) {
                margin-top: 50px;
                padding: 0;
            }
        }
        @include respond-max-width-breakpoint(medium) {
            flex-direction: column;
        }
    }
}

.error-container {
    &__content {
        font-size: 1.125rem;
    }

    &__info-text {
        line-height: 150%;
    }

    &__header {
        font-size: 3.125rem;
    }

    &__button {
        margin-top: 3.125rem;
        font-size: 1.125rem;

        @include respond-max-width-breakpoint(medium) {
            width: 100%;
        }
    }
}
