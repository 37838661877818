@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.aside-content.aside-content--booking-indicator {
    padding-top: 1rem;
    max-width: 250px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 0;

    @include respond-max-width-breakpoint(medium) {
        display: none;
    }
}

.aside-content--time-select {
    .stepindicator__list {
        padding-bottom: 0;
    }

    // Fix to ensure next button is visible when using browser zoom
    @media (max-height: 31.25rem) {
        max-height: 31.25rem;
        overflow: auto;
    }
}

.step-width {
    justify-self: end;
    width: 100%;
    height: fit-content;
    gap: 16px;
    border-radius: 40px;
}
.stepindicator__list {
    list-style: none;
    padding: 0 0.5rem 1.75rem;

    &__item {
        font-size: 0.875rem;
        display: flex;
        gap: 1rem;
        align-items: center;
        padding: 0.5rem 1rem;
        border-radius: 40px;

        &.current-step {
            background-color: rgba($primaryAccentColor, 0.1);
        }
        &.future-step {
            filter: grayscale(100%) opacity(30%);
        }

        > svg {
            color: #e30031;
            min-width: 24px;
        }
        > span {
            display: block;
        }
    }
}

.stepindicator__footer {
    padding: 0 8px 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    .timeslot__spinner {
        padding: 14px;

        & > div {
            width: 32px;
            height: 32px;
        }
    }
}
