@import '../../scss/mixins';
@import '../../scss/variables';

.search_modal {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;

    h2 {
        font-size: 1.125rem;
        margin-bottom: 0.5rem;
    }

    &__skeleton {
        padding-top: 8px;
        border: none;
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &__search {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 8px 24px 12px;
        transition: padding 0.3s;
        input {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &_toggle_button {
        border-radius: 32px;
        height: 40px;
        width: 40px;
        background: rgba(0, 0, 0, 0.05);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &:focus-visible {
            outline: none;

            &::before {
                content: '';
                position: absolute;
                top: -2px;
                right: -2px;
                bottom: -2px;
                left: -2px;
                border: 3px solid #121212;
                border-radius: 32px;
                z-index: 400;
            }
        }

        @supports not selector(:focus-visible) {
            &:focus {
                /* Fallback for browsers without :focus-visible support */
                outline: 3px solid #121212;
            }
        }
    }

    &__error {
        display: flex;
        gap: 16px;
        padding: 12px 24px;
        p {
            line-height: 1.5rem;
        }
    }

    &__footer {
        position: sticky;
        bottom: 0;
        background: #fff;
        padding: 0 !important;
        border: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        border-radius: 0 0 32px 32px;

        &__content {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            padding: 20px 24px 20px 24px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
    .footer__flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .checkbox_group {
        margin: 0 -16px;
    }

    .button_group {
        margin-right: -16px;
    }

    a {
        &:focus-visible {
            outline: 3px solid #121212;
        }

        @supports not selector(:focus-visible) {
            &:focus {
                /* Fallback for browsers without :focus-visible support */
                outline: 3px solid #121212;
            }
        }
    }

    @include respond-max-width-breakpoint(small) {
        // Fallback for browsers that don't support svh vh units
        $customSearchModalHeight: 100%;
        transform: translateY(calc($customSearchModalHeight - 149px));

        @supports (height: 100vh) {
            $customSearchModalHeight: 100vh;
            transform: translateY(calc($customSearchModalHeight - 233px));
        }
        @supports (height: 100svh) {
            $customSearchModalHeight: 100svh;
            transform: translateY(calc($customSearchModalHeight - 210px));
        }
        transition: transform 0.3s, border-radius 0.3s, box-shadow 0.3s, margin 0.3s;

        &--hide {
            transform: translateY(100%);
        }

        .map_modal__scroll {
            overflow-y: hidden;
        }

        &--up {
            transform: translateY(0px);
            border-radius: 0;
            box-shadow: none;

            .map_modal__scroll {
                overflow-y: auto;
                margin-top: 0;
            }

            // Should prevent tab navigation to map markers when search menu is expanded
            & + .map_container {
                visibility: hidden;
                transition: visibility 0.5s;
            }

            & + .navbar {
                visibility: hidden;
                display: none;
                transition: visibility 0.5s;
            }
        }
    }
}

#root {
    min-height: 100%;
    // Fallback for browsers that don't support svh vh units
    @supports (min-height: 100vh) {
        min-height: 100vh; // fallback
    }
    @supports (min-height: 100svh) {
        min-height: 100svh;
    }
    @supports (min-height: -webkit-fill-available) {
        min-height: -webkit-fill-available;
    }

    max-width: 1920px;
    margin: 0 auto;

    &:has(> .App) {
        max-width: unset;
        margin: unset;
    }
}

#root,
.App {
    display: flex;
    flex-direction: column;
}

.App {
    position: relative;
    overflow: hidden;

    .main-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;
        min-height: 60px;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
        position: relative;
        z-index: 1;

        .logo {
            width: 107px;
            height: 28px;
        }

        button {
            color: $primaryAccentColor;
            font-size: 1rem;
            font-variation-settings: 'wght' 400;
        }

        &__links {
            display: flex;
            flex-direction: row;
            gap: 2rem;
        }

        @include respond-max-width-breakpoint(small) {
            padding: 0 1rem;
        }
    }

    & > main {
        position: relative;

        @include respond-max-width-breakpoint(small) {
            position: static;
        }
    }

    .clinic_map_marker {
        opacity: 0;
        animation: fade 0.2s forwards;

        &--hovered {
            z-index: 700 !important;
        }

        &:focus-visible {
            outline: 3px solid #121212;
        }

        @supports not selector(:focus-visible) {
            &:focus {
                /* Fallback for browsers without :focus-visible support */
                outline: 3px solid #121212;
            }
        }
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.checkbox_group {
    display: flex;
    flex-direction: column;
    margin-bottom: 22px;

    &__more {
        margin-left: 16px;
        margin-top: 10px;
    }
}

.button_group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .btn {
        margin-right: 6px;
        margin-bottom: 6px;
    }
}
