@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.vaccination-item-details {
    margin-top: calc(2.3125rem - 2px); // calculated to fit left column of history navigation

    @include respond-max-width-breakpoint(medium) {
        margin-top: 3.825rem;
    }

    &__header {
        position: sticky;
        top: 60px;
        background: white;
        z-index: 1;
        padding-bottom: 1.5rem;
        margin-bottom: 0;
        font-size: 1.5rem;
        font-variation-settings: 'wght' 700;
        line-height: 1.25;
    }

    &__related-vaccination-header {
        margin-top: 2rem;
        font-variation-settings: 'wght' 700;
        line-height: 1.3;
    }

    &__card {
        margin-top: 1rem;

        &:last-child {
            margin-bottom: 2rem;
        }
    }
}
