.confirm-remove-account {
    &__text-content {
        list-style-position: inside;
    }

    &__remove-button {
        width: 100%;
        margin-top: 1.875rem;
    }
}
