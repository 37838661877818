@import '../../../../../scss/mixins';

.consent-content {
    max-width: 600px;

    &__card {
        margin-top: 1rem;
        border-radius: 3.125rem;
        padding: 2.5rem 2rem;
        border: 1px solid rgba(18, 18, 18, 0.15);

        &:first-of-type {
            margin-top: 3.125rem;
        }

        @include respond-max-width-breakpoint(medium) {
            padding: 2.5rem 1.5rem;
            border-radius: 2.5rem;

            &:first-of-type {
                margin-top: 2rem;
            }
        }

        &__radio_header,
        &__patient_name {
            font-family: 'RubikVariable';
            font-variation-settings: 'wght' 600;
        }

        &__radio_header {
            margin-top: 1.375rem;
        }

        &__patient_name,
        &__for {
            font-size: 1.25rem;
        }

        & .radio-group {
            @include respond-max-width-breakpoint(medium) {
                flex-direction: column;
            }
        }

        & Button {
            font-size: 1rem;
            margin-top: 0.375rem;
            padding-left: 0;
        }
    }
    &__button-container {
        margin-top: 3.125rem;
        display: flex;
        justify-content: flex-end;
    }
    .radio-input__text {
        font-size: 1rem;
    }
}
