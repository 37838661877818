@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.main-content {
    &__header {
        font-size: 2.5rem;
        margin-bottom: 1.5rem;

        @include respond-max-width-breakpoint(small) {
            hyphens: auto;
            word-break: break-word;
            white-space: pre-wrap;
        }
    }
    &__text {
        line-height: 1.625rem;
        hyphens: none;
    }
    &__subheader {
        font-size: 1.125rem;
        line-height: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .form-container {
        margin-top: 3.125rem;
        width: 100%;

        @include respond-max-width-breakpoint(medium) {
            margin-top: 0;
        }

        &__header {
            font-size: 1.125rem;
            font-variation-settings: 'wght' 600;
            margin-bottom: 1.5rem;
        }

        .form-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-right: 2rem;
            gap: 1rem;
        }
    }
    .policy-container {
        margin-top: 50px;

        @include respond-max-width-breakpoint(medium) {
            margin-top: 0;
        }

        display: flex;
        flex-direction: column;
        gap: 24px;

        &__mobile-only-header {
            margin-bottom: 0;
            font-size: 1.125rem;
        }
    }
    .confirm-next {
        margin-top: 50px;
        display: flex;
        justify-content: flex-end;

        @include respond-max-width-breakpoint(medium) {
            button {
                width: 100%;
            }
        }
    }
}

.input-error {
    font-size: 0.875rem;
    color: $primaryAccentColor;
    margin: -8px 2rem 0;
}

.checkbox__label {
    hyphens: none;
}

.in-text-link {
    color: $primaryAccentColor;
    padding: 0;
    margin: 0;
    font-size: inherit;
    cursor: pointer;
    text-decoration: underline;
}

.policy-modal {
    @include respond-max-width-breakpoint(small) {
        hyphens: auto;
        word-break: break-word;
        white-space: pre-wrap;
    }
}
