@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.vaccination-item-details-card {
    display: grid;
    grid-auto-rows: auto;
    max-width: 42rem;
    border: 1px solid $gray300;
    border-radius: 2.5rem;
    padding: 1.5rem;

    &__container {
        display: grid;
        gap: 1rem;

        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }

        &:last-child {
            margin-bottom: 0.5rem;
        }

        &__column {
            display: grid;
            grid-template-columns: 1fr;
            gap: 0.125rem;

            &__header {
                font-size: 1.5rem;
                font-variation-settings: 'wght' 700;
                margin-bottom: 0;

                &--small {
                    font-size: 1.25rem;
                    font-variation-settings: 'wght' 700;
                    margin-bottom: 0;
                }
            }
        }

        &__row {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 0.125rem;

            @include respond-max-width-breakpoint(compact) {
                grid-template-columns: 1fr;
                gap: 0.25rem;
            }

            &__left {
                position: relative;
                display: grid;
                grid-template-columns: max-content 1fr;
                align-items: center;
                gap: 1rem;
                @include respond-max-width-breakpoint(compact) {
                    gap: 0.5rem;
                }

                &__icon {
                    position: relative;
                    display: grid;
                    align-items: center;
                    color: $red;
                }
            }

            &__right {
                display: grid;
                grid-template-columns: 1fr auto;
                align-items: center;
                text-align: right;

                @include respond-max-width-breakpoint(compact) {
                    text-align: left;
                    grid-template-columns: auto 1fr;
                }

                &__icon {
                    display: grid;
                    grid-template-columns: 1fr;
                    padding-left: 1rem;
                    @include respond-max-width-breakpoint(compact) {
                        text-align: left;
                    }
                }
            }

            &__actions {
                max-width: fit-content;
                display: inline-flex;
                flex-flow: row wrap;
                gap: 2rem;
                margin-top: 1rem;
                justify-self: flex-end;

                &__button {
                    display: inline-flex;
                    gap: 0.75rem;
                    color: #e30031;
                    font-size: 1rem;
                    font-variation-settings: 'wght' 400;
                }
            }

            &:not(:last-of-type).underline {
                border-bottom: 1px solid $gray300;
                padding-bottom: 1rem;
            }
        }
    }

    &__notification {
        padding: 1rem;
        margin-bottom: 1.5rem;
        background: $light-pink;
        border-radius: 1rem;
        display: grid;
        grid-template-columns: 1fr auto;

        @include respond-max-width-breakpoint(compact) {
            grid-template-columns: 1fr;
            gap: 1rem;
        }

        &__right {
            display: grid;
            grid-template-columns: auto 3fr;

            &__icon {
                display: inline-flex;
                align-self: center;
                justify-self: center;
                margin-right: 1rem;
                @include respond-max-width-breakpoint(x-small) {
                    display: none;
                }
            }

            &__subheader {
                font-weight: 600;
            }
        }
    }
}

.text-secondary {
    // added locally to override global styles // TOTO: remove when typography is refactored
    color: #585858;
}
