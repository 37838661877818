@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.share-consent-modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    word-break: break-word;
    hyphens: auto;

    h1 {
        margin: 0;
    }

    h2 {
        font-size: 1.125rem;
    }

    &__footer {
        margin-top: 1rem;
    }

    &__share-link {
        display: flex;
        justify-content: space-between;
        align-items: center;

        font-size: 1rem;
        border-radius: 2.5rem;
        background: rgba(247, 247, 247, 1);
        padding: 1.5rem;
        color: #757575;

        word-break: keep-all;
        hyphens: none;

        @include respond-max-width-breakpoint(medium) {
            flex-direction: column;
        }
        @include respond-max-width-breakpoint(x-small) {
            word-break: break-word;
            hyphens: auto;
        }

        .btn {
            font-size: 1rem;
            padding: 0.25rem 1rem;
        }
    }
}
