.search-results {
    position: relative;
    overflow-y: auto;

    &__no-results {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 24px 16px;
        font-size: 0.875rem;
        font-variation-settings: 'wght' 500;
        line-height: 1.125rem;
    }

    &__section {
        &:not(:first-child) {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
        }

        padding: 8px 24px 16px;

        & h3 {
            font-size: 0.875rem;
            margin-top: 0;
            font-variation-settings: 'wght' 500;
        }
        & p {
            font-size: 0.875rem;
        }

        &--loading {
            padding: 24px 16px;
        }
    }

    &__loader {
        height: 24px;
        width: 24px;
    }

    li {
        list-style-type: none;
        margin: 0 -16px;
    }
}
