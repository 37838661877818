@import 'src/scss/variables';

.clinic-capacity {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;

    &__row {
        font-size: 0.875rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        line-height: 1.25rem;

        &__value {
            color: $black;
            font-variation-settings: 'wght' 500;
        }
    }

    &__next-slot::first-letter {
        text-transform: capitalize;
    }
}
