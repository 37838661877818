//
// Spacing utilities
// Easily apply a specific spacing to an element.
//

$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer * 1,
    4: $spacer * 1.5,
    5: $spacer * 2,
    6: $spacer * 2.5,
    7: $spacer * 3,
    8: $spacer * 3.5
);

// Allows for the use of classes in the form of
// m-{size}, m(t|r|b|l|x|y)-{size}, p-{size}, p(t|r|b|l|x|y)-{size}
// Examples: m-0, mt-0, pb-3, px-5, py-8
@each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $length in $spacers {
        .#{$abbrev}-#{$size} {
            #{$prop}: $length !important;
        }
        .#{$abbrev}t-#{$size},
        .#{$abbrev}y-#{$size} {
            #{$prop}-top: $length !important;
        }
        .#{$abbrev}r-#{$size},
        .#{$abbrev}x-#{$size} {
            #{$prop}-right: $length !important;
        }
        .#{$abbrev}b-#{$size},
        .#{$abbrev}y-#{$size} {
            #{$prop}-bottom: $length !important;
        }
        .#{$abbrev}l-#{$size},
        .#{$abbrev}x-#{$size} {
            #{$prop}-left: $length !important;
        }
    }
}

.m-auto {
    margin: auto !important;
}
.mt-auto,
.my-auto {
    margin-top: auto !important;
}
.mr-auto,
.mx-auto {
    margin-right: auto !important;
}
.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
    margin-left: auto !important;
}
