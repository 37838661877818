.consent-status {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    &__person {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__name {
        display: block;
        font-family: 'RubikVariable';
        font-variation-settings: 'wght' 500;
    }
}
