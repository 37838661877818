@import '../../../scss/mixins';

.list_modal {
    display: flex;
    flex-direction: column;
    width: 350px;
    top: 0;
    bottom: 0;
    right: 0;
    transition: transform 200ms;
    transform: translateX(500px);

    &-enter-active {
        transform: translateX(500px);
    }

    &-enter-done {
        transform: translateX(0);
    }

    &-exit-start {
        transform: translateX(500px);
    }

    &-exit-done {
        transform: translateX(500px);
    }

    &__title {
        span {
            font-size: 1.25rem;
        }
    }

    &__county-list {
        padding: 8px;
        list-style-type: none;
        display: flex;
        gap: 2px;

        li {
            display: flex;
        }

        &_button {
            flex: 1;
            position: relative;
            text-align: left;
            padding: 8px 16px;
            border-radius: 16px;
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: 400;
            font-variation-settings: 'wght' 400;
            color: #121212;
            -webkit-tap-highlight-color: rgba(227, 0, 49, 0.1);

            @media (hover: hover) {
                &:hover {
                    background: rgba(227, 0, 49, 0.1);
                }
            }

            &:focus-visible {
                outline: none;

                &::before {
                    content: '';
                    position: absolute;
                    top: -2px;
                    right: -2px;
                    bottom: -2px;
                    left: -2px;
                    border: 3px solid #121212;
                    border-radius: 16px;
                    z-index: 400;
                }
            }

            &:active {
                background: rgba(227, 0, 49, 0.1);
            }

            @supports not selector(:focus-visible) {
                &:focus {
                    /* Fallback for browsers without :focus-visible support */
                    outline: 3px solid #121212;
                }
            }
        }
    }

    &__close_button {
        border-radius: 32px;
        height: 40px;
        width: 40px;
        background: rgba(227, 0, 49, 0.04);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        svg * {
            fill: #e30031;
        }

        @media (hover: hover) {
            &:hover {
                background: rgba(227, 0, 49, 0.1);
            }
        }

        &:focus-visible {
            outline: none;

            &::before {
                content: '';
                position: absolute;
                top: -2px;
                right: -2px;
                bottom: -2px;
                left: -2px;
                border: 3px solid #121212;
                border-radius: 32px;
                z-index: 400;
            }
        }

        @supports not selector(:focus-visible) {
            &:focus {
                /* Fallback for browsers without :focus-visible support */
                outline: 3px solid #121212;
            }
        }
    }

    @include respond-max-width-breakpoint(small) {
        margin: 0;
        width: 100vw;
        border-radius: initial;
    }
}

.clinic_list {
    margin: 4px 0;

    &_link {
        padding: 0 24px 16px;
    }

    &__loader {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        height: 100px;
    }
}
