.pick-visit-category {
    padding: 1.5rem;
    border: none;
    &__heading {
    }

    &__radio-control {
        .radio-input {
            &__label {
                flex-direction: row-reverse;
                justify-content: space-between;
                font-size: 1rem;
                padding: 8px 16px;
                border-radius: 1rem;
                margin: 0 -1rem;
                box-shadow: none;

                &:has(:focus-visible) {
                    box-shadow: 0 0 0 3px #121212;
                    outline: none;
                }

                // Specific target for firefox, since they don't support ":has" yet
                @supports not selector(input:has(:focus-visible)) {
                    &:focus-within {
                        box-shadow: 0 0 0 3px #121212;
                        outline: none;
                    }
                }
            }
        }
    }
}
