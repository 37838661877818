.policy-article {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    margin-bottom: 1rem;

    &__section {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}
