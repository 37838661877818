@import '../../../scss/mixins';
@import '../../../scss/variables';

.map_controls {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 400;

    transition: all 200ms ease;

    &--shifted {
        right: 366px;

        @include respond-max-width-breakpoint(medium) {
            display: none;
        }
    }

    &:last-child {
        margin-right: 0;
    }

    // Zoomed in, attempt to fit all buttons
    @media (max-width: 300px) {
        align-items: flex-end;
        flex-wrap: wrap;
        justify-content: flex-end;
        gap: 4px;

        & > div {
            margin-right: 0;
        }

        .map_control {
            margin-right: 0;
        }
    }
}

.map_control_toast_wrapper {
    position: relative;
    margin-right: 24px;

    .map_control_toast {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 14px 16px;
        border-radius: 16px;
        font-family: 'RubikVariable';
        color: #fff;
        font-size: 1rem;
        position: absolute;
        right: -8px;
        background: $primaryColor;
        transform: translateY(16px);
        white-space: nowrap;

        p {
            line-height: 1.5rem;
            display: flex;
            align-items: center;

            &:focus-visible {
                outline: 2px solid #121212;
            }

            button {
                height: 16px;
                margin-left: 16px;
                color: #fff;

                &:focus-visible {
                    outline: 2px solid #121212;
                }

                &:hover {
                    svg * {
                        fill: #ccc;
                    }
                }
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            background: $primaryColor;
            width: 16px;
            height: 16px;
            display: inline-block;
            transform: rotate(45deg) translate(-18px, 12px);
            -webkit-transform: rotate(45deg) translate(-18px, 12px);
            z-index: 1;
        }

        @include respond-max-width-breakpoint(large) {
            right: -130px;

            &::after {
                transform: rotate(45deg) translate(-105px, 96px);
                -webkit-transform: rotate(45deg) translate(-105px, 96px);
            }
        }

        @include respond-max-width-breakpoint(medium) {
            right: -215px;

            &::after {
                transform: rotate(45deg) translate(-165px, 155px);
                -webkit-transform: rotate(45deg) translate(-165px, 155px);
            }
        }

        @include respond-max-width-breakpoint(x-small) {
            display: none;
        }
    }
}
