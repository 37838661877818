@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.confirm-unbooking-modal {
    h1 {
        hyphens: auto;
        word-break: break-word;
    }

    &__buttons {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        gap: 1rem;
        margin-top: 2rem;

        @include respond-max-width-breakpoint(medium) {
            flex-direction: column;
        }
    }

    &__confirm-button {
        flex: 1;
    }
}
