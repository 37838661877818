@import '../../../scss/mixins';
@import '../../../scss/variables';
.tab-menu {
    display: grid;
    grid-auto-flow: column;
    justify-self: flex-start;
    grid-column: 1 / -1;
    gap: 1rem;

    @include respond-max-width-breakpoint(x-small) {
        // to handle zoom 200%
        gap: 0rem;
        grid-auto-flow: row;
        justify-items: flex-start;
    }
    &__item {
        color: #757575;

        &--selected {
            width: fit-content;
            position: relative;
            font-variation-settings: 'wght' 500;
            color: $primaryAccentColor;

            &::after {
                grid-area: left-column;
                content: '';
                position: absolute;
                left: 0;
                bottom: -0.5rem;
                width: 100%;
                height: 0.25rem;
                background-color: $primaryAccentColor;
                border-radius: 2px;
                box-shadow: 0px 40px 11px rgba(102, 0, 20, 0.01), 0px 25px 10px rgba(102, 0, 20, 0.04),
                    0px 14px 9px rgba(102, 0, 20, 0.15), 0px 6px 6px rgba(102, 0, 20, 0.26),
                    0px 2px 4px rgba(102, 0, 20, 0.29), 0px 0px 0px rgba(102, 0, 20, 0.3);
            }
        }
    }
}
