//
// Sizing utilities
// Easily apply a specific size and max size to an element.
//

$sizes: (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
);

// Allows for the use of classes in the form of w-{size} or h-{size}
// Examples: w-50, h-100, w-auto, h-auto
@each $prop, $abbrev in (width: w, height: h) {
    @each $size, $length in $sizes {
        .#{$abbrev}-#{$size} {
            #{$prop}: $length !important;
        }
    }
}

.maxw-100 {
    max-width: 100% !important;
}
.maxh-100 {
    max-height: 100% !important;
}
