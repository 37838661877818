.pills-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    gap: 8px;

    list-style: none;
    cursor: default;
    margin-top: 8px;

    &:empty {
        margin-top: 0;
    }
}

.pill {
    background-color: rgba(227, 0, 49, 1);
    color: white;
    padding: 12px;
    border-radius: 20px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;

    p {
        display: flex;
        align-items: center;
        gap: 8px;
        button {
            height: 16px;
            cursor: pointer;

            &:focus-visible {
                outline: 3px solid #121212;
            }

            @supports not selector(:focus-visible) {
                &:focus {
                    /* Fallback for browsers without :focus-visible support */
                    outline: 3px solid #121212;
                }
            }

            svg * {
                fill: #fff;
            }
        }
    }
}
