@import 'src/scss/variables.scss';
@import 'src/scss/mixins';

.manual-vaccination-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2.5rem;

    &--hidden {
        visibility: hidden;
    }

    &__vaccine-info-link {
        display: flex;
        align-items: flex-start;
        gap: 0.5rem;
        font-weight: 400;
        font-size: 1rem;

        @supports (font-variation-settings: normal) {
            font-variation-settings: 'wght' 400;
        }

        &__icon {
            flex-shrink: 0;
            width: 16px;
        }
    }

    &__notification-header {
        display: flex;
        flex-wrap: wrap;
        font-size: 1.25rem;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }

    &__notification-subtitle {
        font-size: 1rem;
        color: $gray600;
        margin-bottom: 1rem;
    }

    &__notification-date {
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;
        gap: 2rem;

        &__select {
            width: 269px;
            max-width: 100%;
        }

        &__reset-button {
            padding: 0.375rem;
        }
    }
}

.manual-vaccination-form-spinner {
    position: absolute;
    top: 50%;
    left: calc(50% - 1.25rem); // not sure why but it's not centered without subtracting half of the width
    justify-content: center;
    width: 2.5rem;
}
