@import '../../../scss/variables';
@import '../../../scss/mixins';

.handle-history-identify {
    width: 640px;
    max-width: 100%;

    @include respond-max-width-breakpoint(medium) {
        width: auto;
    }

    &__heading {
        font-size: 2.5rem;
        font-variation-settings: 'wght' 700;
        line-height: 1.15;
        margin: 0;
    }

    &__sub-heading {
        font-size: 1.125rem;
        margin-top: 1.5rem;
    }

    &__identity-heading {
        font-size: 1.125rem;
        margin-top: 2.5rem;
    }
}
