* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    height: 100vh; // fallback
    height: stretch;
    height: -webkit-fill-available;
    height: calc(var(--vhfallback, 1vh) * 100);
    height: 100svh;
}

body {
    font-family: 'Rubik', sans-serif;
    font-size: 1rem;
    color: $black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.map-view {
        overflow-y: hidden;
    }
}

button,
input[type='button'] {
    cursor: pointer;
}

input,
textarea,
button,
select,
label,
a {
    -webkit-tap-highlight-color: transparent;
}

button {
    line-height: 1rem;
    border: none;
    background: transparent;
}
