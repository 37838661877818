@import '../../../../../scss/variables';
@import '../../../../../scss/mixins';

.people {
    &__list-section {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        &__list {
            display: flex;
            flex-direction: column;
            gap: 16px;
            > ul {
                display: grid;
                gap: 16px;
            }
        }

        Button {
            align-self: flex-end;
            margin-top: 3.125rem;

            @include respond-max-width-breakpoint(medium) {
                align-self: center;
                width: 100%;
                margin-top: 2rem;
            }
        }
    }
    &__error {
        margin-top: 1.5rem;
        padding: 1rem 1.5rem;
        background-color: rgba(227, 0, 49, 0.04);
        border-radius: 2rem;
        p {
            line-height: 150%;
        }
        button.primary {
            display: inline-block;
            text-decoration: underline;
            font-family: 'RubikVariable';
            font-variation-settings: 'wght' 500;
        }
    }
    &__text {
        margin-bottom: 1rem;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
