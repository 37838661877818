@use 'sass:color';
@import '../../../scss/variables';

.toggle_box_container {
    margin-bottom: 2px;
    position: relative;

    &.disabled {
        opacity: 0.3;
        pointer-events: none;
    }

    .toggle_box {
        padding: 5px 13px;
        border-radius: 16px;
        line-height: 1.5rem;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: 3px solid transparent;
        user-select: none;
        cursor: pointer;

        &.big {
            padding: 24px 32px;
            border-radius: 40px;
            border: 1px solid rgba(18, 18, 18, 0.15);
            font-family: 'RubikVariable';
        }

        @media (hover: hover) {
            &:hover {
                background: rgba(227, 0, 49, 0.04);
            }
        }

        .with_sublabel {
            display: flex;
            flex-direction: column;
            width: 80%;
            font-size: 1.25rem;
        }

        .checkbox__label {
            max-width: 80%;
            overflow-wrap: break-word;
            word-break: break-word;
            hyphens: auto;

            font-variation-settings: 'wght' 700;
        }

        .checkbox__sub-label {
            font-variation-settings: 'wght' 400;
        }

        .checkbox {
            width: 24px;
            height: 24px;
            border: 1px solid rgba(0, 0, 0, 0.25);
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    input[type='checkbox']:focus-visible + .toggle_box {
        outline: none;
        border-color: transparent;
        box-shadow: 0 0 0 3px #121212;
    }

    input[type='checkbox']:checked + .toggle_box {
        background: $primaryColor;
        color: $secondaryTextColor;

        @media (hover: hover) {
            &:hover {
                background: color.scale($primaryColor, $lightness: -10%);
            }
        }

        .checkbox {
            border-color: transparent;
        }
    }

    // Hide the actual checkbox
    input[type='checkbox'] {
        position: absolute;
        clip: rect(1px, 1px, 1px, 1px);
        padding: 0;
        border: 0;
        height: 1px;
        width: 1px;
        overflow: hidden;
    }
}
