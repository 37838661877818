@import '../../../../scss/mixins';

.booking_skeleton {
    width: 100%;
    max-width: 645px;
    padding: 3.125rem 0 0;
    border-radius: 3.125rem;
    border: 1px solid rgba(18, 18, 18, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    div {
        overflow: hidden;
    }

    &:only-of-type {
        margin-top: 3.125rem;
    }

    &__title {
        max-width: 300px;
        height: 32px;
        margin: 0 42px 42px;
        border-radius: 16px;
        @include respond-max-width-breakpoint(medium) {
            width: 70%;
        }
    }
    &__row {
        margin-top: 44px;
        display: flex;
        margin: 0px 42px 1.75rem 42px;
        &__icon {
            width: 24px;
            height: 24px;
            margin-right: 1rem;
            border-radius: 6px;
        }
        &__text-container {
            height: 39px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &__text {
                height: 16px;
                border-radius: 16px;
                width: 127px;
                &:first-of-type {
                    width: 156px;
                }
            }
        }
    }
    &__footer {
        border-top: 1px solid rgba(18, 18, 18, 0.15);
        margin-top: 27px;
        .booking_skeleton__row {
            margin: 27px 42px 27px 42px;
            &__text-container {
                height: 16px;
            }
        }
    }
}
