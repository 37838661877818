@import '../../scss/variables';
@import '../../scss/mixins/breakpoints';

.main-content {
    grid-area: main;
}
.login {
    &__header {
        font-size: 2.5rem;
        margin-bottom: 1.5rem;
    }
    &__text {
        line-height: 1.625rem;
        hyphens: none;
    }

    &__section {
        &__header {
            font-size: 1.125rem;
            margin-top: 2.5rem;
            margin-bottom: 1rem;
            font-variation-settings: 'wght' 600;
        }
        &__buttons {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            &__button {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap-reverse;
                padding: 24px 32px;
                border: 1px solid rgba(18, 18, 18, 0.15);
                border-radius: 40px;
                font-size: 1.25rem;
                font-variation-settings: 'wght' 700;
                line-height: 1.25;
                position: relative;
                color: $primaryTextColor;
                transition: background-color 0.2s ease-in-out;
                min-height: 90px;

                @include respond-max-width-breakpoint(medium) {
                    padding: 24px;
                    font-size: 1.125rem;
                }
                span {
                    text-align: left;
                    min-width: 100px;
                }
                svg {
                    height: 40px;
                }

                &:hover {
                    cursor: pointer;
                    background-color: rgba(227, 0, 49, 0.1);
                }
                &:active {
                    transition: all 0.2s ease;
                    transform: scale(0.99);
                }
                &.active {
                    background-color: rgba(227, 0, 49, 0.1);
                }
            }
        }
    }
}
