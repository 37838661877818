@import '../../../../scss/mixins';
@import '../../../../scss/variables';
.map-mobile-menu {
    display: flex;
    flex-direction: column;
    background: #fef5f7;
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    overflow-y: auto;
    text-align: left;
    transition: 0.2s;
    transform: translateX(100vw);

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin: 2rem 2rem 2.1875rem;
    }

    &__close-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__main-links {
        display: flex;
        flex-direction: column;
        gap: 0.6em;
        font-size: 1.5rem;
        line-height: 1.875rem;
        padding-bottom: 2rem;
        list-style-type: none;

        a {
            text-decoration: none;
            font-variation-settings: 'wght' 400;
            overflow-wrap: break-word;
            hyphens: auto;
        }
    }

    &__main-bottom-links {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-end;
        gap: 0.6rem;
        font-size: 1.5rem;
        line-height: 1.875rem;
        padding-bottom: 2rem;
        list-style-type: none;
        border-bottom: 0.0625rem solid #fbdce3;

        &__icon {
            display: inline-flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            height: 1.875rem;
            gap: 0.5rem;
        }

        a {
            overflow-wrap: break-word;
            hyphens: auto;
            text-decoration: none;
            font-variation-settings: 'wght' 400;
        }
    }

    &__content {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin: 0 2rem;
    }

    &__policy-links {
        display: flex;
        flex-direction: column;
        gap: 0.6em;
        line-height: 2rem;
        font-size: 0.875rem;
        margin: 2rem 0 9.75rem;

        a {
            overflow-wrap: break-word;
            hyphens: auto;
        }
    }
    &-enter {
        transform: translateX(100vw);
    }

    &-enter-active {
        transform: translateX(100vw);
    }

    &-enter-done {
        transform: translateX(0);
    }

    &-exit {
        transform: translateX(0);
    }

    &-exit-active {
        transform: translateX(100vw);
    }

    &-exit-done {
        transform: translateX(100vw);
    }
}
