@import 'src/scss/variables.scss';

.manual-vaccination-modal-header {
    font-size: 2.5rem;
    font-variation-settings: 'wght' 700;
    line-height: 1.15;
    margin-bottom: 0;
}

.manual-vaccination-action-modal-header {
    font-size: 1.5rem;
    font-variation-settings: 'wght' 700;
    line-height: 1.25;
    margin-bottom: 0;

    &__action-button {
        margin-top: 1rem;
        width: 100%;
    }
}

.manual-vaccination-modal-subtitle {
    color: $gray600;
    font-size: 1.125rem;
    font-variation-settings: 'wght' 400;
    line-height: 1.25;
    margin-top: -0.5rem; // to account for ModalPortal h1 bottom margin
    margin-bottom: 2.5rem;
}
