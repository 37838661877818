//
// Text utilities
// Easily apply a specific property to a textual element.
//

//
// Wrapping
//

.t-justify {
    text-align: justify !important;
}
.t-nowrap {
    white-space: nowrap !important;
}
.t-pre {
    white-space: pre-line !important;
}
.t-truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

//
//  Alignment
//

.t-left {
    text-align: left !important;
}
.t-right {
    text-align: right !important;
}
.t-center {
    text-align: center !important;
}

//
// Transformation
//

.t-lower {
    text-transform: lowercase !important;
}
.t-upper {
    text-transform: uppercase !important;
}
.t-caps {
    text-transform: capitalize !important;
}

//
// Weight and italics
//

.t-normal {
    font-weight: 400 !important;
    font-variation-settings: 'wght' 400 !important;
}
.t-bold {
    font-weight: 600 !important;
    font-variation-settings: 'wght' 600 !important;
}
.t-bolder {
    font-weight: 700 !important;
    font-variation-settings: 'wght' 700 !important;
}
.t-italic {
    font-style: italic !important;
}
