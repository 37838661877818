@import '../../../scss/variables';
@import '../../../scss/mixins';

.snack-bar {
    display: flex;
    border-radius: 1.25rem;
    padding: 1rem;
    min-width: 21.875rem;
    background: #121212;
    color: $secondaryTextColor;
    position: fixed;
    bottom: 2rem;
    left: 2rem;
    transition: opacity 500ms;
    z-index: 10;

    &__icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
        background-color: #4b9d57;
        border-radius: 1rem;
    }

    &.exit-active {
        opacity: 0;
    }

    @include respond-max-width-breakpoint(medium) {
        min-width: auto;
        display: flex;
        flex: 1;
        right: 1rem;
        left: 1rem;
    }
}
