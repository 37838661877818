@import '../../../scss/variables';
@import '../../../scss/mixins';

.handle-container {
    margin: 0 2rem;
    @include respond-max-width-breakpoint(compact) {
        margin: 0 1rem;
    }

    main {
        margin: 2rem 0;
        @include respond-max-width-breakpoint(medium) {
            word-break: break-word;
            hyphens: auto;
        }
    }
    &__spinner {
        display: flex;
        flex: 1;
        padding-top: 20%;
        flex-direction: column;

        &__icon {
            align-self: center;
            width: 2.5rem;
        }
    }
}

.handle-booking {
    &__heading {
        font-size: 2.5rem;
        font-variation-settings: 'wght' 700;
        line-height: 1.15;
        margin: 0;
    }

    &__extra-heading {
        font-size: 2.5rem;
        font-weight: 400;
        font-variation-settings: 'wght' 400;
        margin: 0;
    }

    &__sub-heading {
        font-size: 1.125rem;
        margin-top: 1.5rem;
    }

    &__identify {
        width: 640px;
        max-width: 100%;

        &__heading {
            margin-top: 2.5rem;
            font-size: 1.125rem;
        }

        @include respond-max-width-breakpoint(medium) {
            width: auto;
        }
    }

    &__identify-booking-code {
        margin-bottom: 1rem;
    }

    &--content {
        margin-top: 50px;

        &__options__buttons {
            display: flex;
            flex-direction: column;
            gap: 16px;

            &__button {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap-reverse;
                padding: 24px 32px;
                border: 1px solid rgba(18, 18, 18, 0.15);
                border-radius: 40px;
                font-size: 1.25rem;
                font-variation-settings: 'wght' 700;
                line-height: 1.25;
                position: relative;
                color: $primaryTextColor;
                transition: background-color 0.2s ease-in-out;
                max-width: 640px;
                width: 100%;

                @include respond-max-width-breakpoint(medium) {
                    padding: 24px;
                    font-size: 1.125rem;
                }
                span {
                    text-align: left;
                    min-width: 100px;
                }
                svg {
                    height: 40px;
                }

                &:hover {
                    cursor: pointer;
                    background-color: rgba(227, 0, 49, 0.1);
                }
                &:active {
                    transition: all 0.2s ease;
                    transform: scale(0.99);
                }
                &.active {
                    background-color: rgba(227, 0, 49, 0.1);
                }
            }
        }
    }
}
