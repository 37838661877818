@import '../../scss/variables';
@import '../../scss/mixins';

.history-container {
    word-break: break-word;
    hyphens: auto;

    &__heading {
        font-size: 2.5rem;
        padding-bottom: 1.5rem;
    }

    &__spinner {
        display: flex;
        flex: 1;
        padding-top: 20%;
        flex-direction: column;

        &__icon {
            align-self: center;
            width: 2.5rem;
        }
    }
}
