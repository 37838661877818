@import '../../scss/variables';
@import '../../scss/mixins';

.policy-container {
    &__content {
        max-width: 640px;
        margin-top: 2rem;

        h1 {
            font-size: 2.5rem;
            margin: 0;
        }
        h2 {
            margin: 0;
        }

        @include respond-max-width-breakpoint(small) {
            hyphens: auto;
            word-break: break-word;
            white-space: pre-wrap;
        }
    }

    &__footer {
        margin-top: 3.125rem;
    }
}
