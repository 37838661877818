@import '../../../scss/variables';
@import '../../../scss/mixins';
.map-policy-menu {
    padding: 0 1rem;
    border-radius: 0.5rem 0 0 0;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #fff;
    color: #121212;
    z-index: 400;
    display: flex;
    gap: 1rem;
    a {
        font-size: 0.625rem;
        text-decoration: underline;
        color: #121212;
        font-family: 'RubikVariable';
        font-variation-settings: 'wght' 400;
        height: auto;
    }
    @include respond-max-width-breakpoint(small) {
        display: none;
    }
}
