h1,
h2 {
    font-size: 1.25rem;
    font-weight: 700;
    letter-spacing: -0.01em;
    margin-bottom: 16px;
}

h3 {
    letter-spacing: -0.01em;
    margin-bottom: 8px;
}

a {
    color: $primaryAccentColor;
    font-weight: 500;
    line-height: 1.375rem;
    text-decoration-line: underline;
    &:focus {
        outline: 2px solid black;
        outline-offset: 2px;
    }

    @media (hover: hover) {
        &:hover {
            color: color.scale($primaryColor, $lightness: -20%);
        }
    }
}

p {
    line-height: 1.5;
}

b {
    font-weight: 600;
}

input {
    font-family: inherit;
}

@supports (font-variation-settings: normal) {
    h1,
    h2,
    legend,
    h3,
    h4 {
        font-family: 'RubikVariable';
        font-variation-settings: 'wght' 600;
    }

    a,
    input[type='button'],
    button {
        font-family: 'RubikVariable';
        font-variation-settings: 'wght' 500;

        &:focus-visible {
            outline: 2px solid black;
        }
        @supports not selector(:focus-visible) {
            &:focus {
                /* Fallback for browsers without :focus-visible support */
                outline: 2px solid #121212;
            }
        }
    }

    b {
        font-family: 'RubikVariable';
        font-variation-settings: 'wght' 600;
    }
}
