@import 'src/scss/variables';

$display-types: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex;

// Allows for the use of classes in the form of d-{display-type} or d-{breakpoint}-{display-type}
@each $name in map-keys($breakpoints) {
    @include respond-max-width-breakpoint($name) {
        $infix: if($name == 'large', '', '-#{$name}');

        @each $value in $display-types {
            .d#{$infix}-#{$value} {
                display: $value !important;
            }
        }
    }
}
