@import '../../scss/variables';
@import '../../scss/mixins';

.account-main-account-container {
    display: flex;
    flex: 1;
    flex-direction: column;

    height: 100%;
}

.account-main-auth-container {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 640px;

    &__heading {
        font-size: 2.5rem;
        font-variation-settings: 'wght' 700;
        line-height: 1.15;
        margin: 0;
    }

    &__sub-heading {
        font-size: 1.125rem;
        margin-top: 1.5rem;
    }

    &__identity-heading {
        font-size: 1.125rem;
        margin-top: 2.5rem;
    }

    &__spinner {
        display: flex;
        flex: 1;
        padding-top: 20%;
        flex-direction: column;

        &__icon {
            align-self: center;
            width: 2.5rem;
        }
    }
}
