@import '../../../scss/variables';
@import '../../../scss/mixins';

.eid-modal {
    font-size: 1rem;

    h1 {
        font-size: 2.5rem;
        letter-spacing: -0.01em;
        line-height: 115%;
        > span {
            font-variation-settings: 'wght' 400;
        }
    }

    &__other-device > div {
        > svg {
            width: 32px !important;
            height: 32px !important;
            margin-bottom: 50px;
            margin-top: 40px;
        }
    }

    &__same-device-button {
        font-size: 1.125rem;
        width: 100%;
        height: 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > svg {
            width: 32px;
            filter: brightness(0) invert(1);
            * {
                fill: #fff;
            }
        }
    }

    &__spinner {
        width: 2rem;
        height: 2rem;
        margin-bottom: 1rem;
        margin-top: 2.5rem;
    }

    &__qr-code {
        width: 100%;
        border: 1px solid rgba(18, 18, 18, 0.15);
        border-radius: 3.5rem;
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            width: 40px !important;
            height: 40px !important;
        }
    }

    &__instructions {
        margin-top: 2rem;
    }

    &__instructions-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        list-style-type: none;
    }

    &__footer {
        margin: 2.5rem 0 0;
        padding: 2rem 0 0;
        border-top: 1px solid rgba(18, 18, 18, 0.15);

        @include respond-max-width-breakpoint(medium) {
            margin: 2.5rem -1rem 0;
            padding: 2rem 1rem 0;
        }

        &--same-device {
            margin-top: 3rem;
            line-height: 150%;
        }
    }

    &__footer-link {
        color: $primaryAccentColor;
        border: none;
        font-size: 1rem;
        font-variation-settings: 'wght' 500;
        padding: 0;
        text-decoration: underline;
        margin-top: 0.5rem;
    }

    &__error {
        font-size: 1.125rem;
        margin-bottom: 0.5rem;
    }

    &__error-footer {
        margin-top: 2.5rem;
    }
}
