.booking-consent {
    &__subheader {
        font-size: 1.125rem;
        margin-bottom: 2rem;
    }
    &__user-consent {
        font-family: 'RubikVariable';
        font-variation-settings: 'wght' 600;
        margin-bottom: 1rem;
        font-size: 1rem;
    }
    &__section {
        padding-top: 3.125rem;

        & .checkbox-container > .checkbox_box > .checkbox {
            margin-top: 0;
        }
        &:first-of-type {
            margin-top: 3.125rem;
            border-top: 1px solid rgba(18, 18, 18, 0.15);
        }
        & .radio-input-information {
            padding: 1.5rem 2.25rem;
            border-radius: 2rem;
            margin-bottom: 1rem;
            background-color: rgba(227, 0, 49, 0.04);
        }
        & .radio-with-input {
            &.border-bottom {
                padding-bottom: 1.75rem;
                border-bottom: 1px solid rgba(18, 18, 18, 0.15);
            }

            & .radio-input {
                border: none;
            }
        }
        & .radio-input {
            padding: 1rem 0;
            border-bottom: 1px solid rgba(18, 18, 18, 0.15);

            &:first-of-type {
                padding: 0;
            }
        }
        & .radio-input__label {
            margin-left: -13px;
            font-size: 1rem;
            &:hover {
                background-color: transparent;
            }
        }
    }
    &__button-container {
        margin-top: 3.125rem;
        display: flex;
        justify-content: flex-end;
    }
}
