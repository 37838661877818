@import '../../../scss/variables';
@import '../../../scss/mixins';

.cookie-modal {
    display: grid;
    grid-template-columns: 1fr auto;

    @include respond-max-width-breakpoint(medium) {
        grid-template-columns: 1fr;
    }

    &__content {
        display: grid;
        font-size: 0.875rem;
        word-break: keep-all;
        color: $gray700;
        margin: 0;
        @include respond-max-width-breakpoint(expanded) {
            padding: 0 0 1rem 0;
        }
    }

    &__heading {
        font-size: 1.25rem;
        line-height: 1.5rem;
        font-weight: 700;
        @supports (font-variation-settings: normal) {
            font-variation-settings: 'wght' 700;
        }
        color: $primaryTextColor;
        margin: 0 0 0.5rem 0;
    }

    &__buttons {
        display: grid;
        grid-area: 1 / 2 / 2 / 3;
        align-self: flex-end;
        gap: 1rem;
        font-size: 0.875rem;
        grid-template-columns: 1fr 1fr;
        hyphens: none;
        word-wrap: normal;
        word-break: keep-all;
        padding: 0.1rem;
        .btn {
            font-size: 0.875rem;
            width: 100%;
            padding: 0.625rem 0.75rem;
        }

        @include respond-max-width-breakpoint(expanded) {
            grid-area: 2 / 1 / 3 / 2;
        }

        @include respond-max-width-breakpoint(medium) {
            grid-area: 2 / 1 / 3 / 2;
        }
        @include respond-max-width-breakpoint(compact) {
            grid-template-columns: 1fr;
            justify-self: center;
            width: 100%;
        }
    }
}
