@import '../../../../../scss/variables';
@import '../../../../../scss/mixins';

.departure-date {
    .react-datepicker__input-container {
        display: flex;
    }

    &__native {
        font-family: 'Rubik';
        box-shadow: 0 0 0 1px rgba(18, 18, 18, 0.15);
        border-radius: 2.5rem;
        padding: 1.00625rem 2rem;
        display: flex;
        flex-direction: column;

        &-label {
            font-size: 0.875rem;
            line-height: 1.25rem;
        }

        &-input {
            font-family: 'Rubik';
            font-size: 1.125rem;
            line-height: 125%;
            color: $primaryTextColor;
            border: none;
            padding: 0;

            background: rgba(18, 18, 18, 0.05);
            height: 2rem;

            appearance: none;
            -webkit-appearance: none;
            white-space: nowrap;

            &::-webkit-date-and-time-value {
                text-align: left;
            }

            &:valid,
            &:active {
                background: #fff;
            }
        }

        &:active {
            outline: none;
        }

        &:focus {
            outline: none;
            box-shadow: 0 0 0 2px #121212;
        }

        &:focus-visible {
            outline: 2px solid #121212;
        }
    }

    &__button {
        position: relative;
        font-family: 'Rubik';
        font-size: 1.25rem;
        line-height: 125%;
        color: $primaryTextColor;
        box-shadow: 0 0 0 1px rgba(18, 18, 18, 0.15);
        border-radius: 2.5rem;
        padding: 1.7815rem 1.75rem 1.7815rem 2rem;
        text-align: left;
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: space-between;
        align-items: center;

        appearance: none;
        white-space: nowrap;

        &--with-value {
            padding: 1.15625rem 1.75rem 1.15625rem 2rem;

            .departure-date__button-content {
                display: flex;
                flex-direction: column;
            }

            .departure-date__button-label {
                // position: absolute;
                // top: 0;
                font-size: 0.875rem;
                line-height: 1.25rem;
            }
        }

        &:focus-visible {
            outline: none;
            box-shadow: 0 0 0 3px #121212;
        }

        &:hover {
            cursor: pointer;
            background-color: rgba($primaryAccentColor, 0.1);
        }

        svg {
            width: 24px;
            height: 24px;
        }
    }

    &__button-content {
        display: flex;
        flex: 1;
        width: 100%;
    }
}
