@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.booking-info-modal {
    h1 {
        hyphens: auto;
        word-break: break-word;
    }

    &__footer {
        margin-top: 2rem;
    }
}

.booking-info-form {
    &__fields {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__footer {
        font-size: 1rem;
        margin-top: 1rem;
    }
}
