@import '../../../scss/variables';
@import '../../../scss/mixins';
@import '../../../scss/animations';

// Prevent clicks behind modal background
body.modal--open #root {
    pointer-events: none;
}

.modal-portal-background + .modal-portal + .modal-portal-background {
    background: transparent;
}

.modal-portal:nth-last-child(n + 2) {
    visibility: hidden;
}

.modal-portal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh; // fallback
    min-height: calc(var(--vhfallback, 1vh) * 100);
    min-height: 100dvh;
    overflow-y: hidden;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 3;
}

.modal-portal {
    display: flex;
    flex-direction: column;
    position: fixed;
    overflow: hidden;
    width: 560px;
    left: 50%;
    top: 50%;
    max-height: calc(100% - 3rem);
    transform: translate(-50%, -50%);
    background: #fff;
    z-index: 4;
    border-radius: 2rem;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);

    &:focus {
        outline: none;
    }

    &--enter-animation,
    &--small--enter-animation {
        animation-name: fade-in;
        animation-duration: 0.2s;
        animation-fill-mode: forwards;
    }

    &--exit-animation,
    &--small--exit-animation {
        animation-name: fade-out;
        animation-duration: 0.2s;
        animation-fill-mode: forwards;
    }

    &--dark {
        background: black;
        color: white;
    }

    &__placement-bottom {
        top: unset;
        font-size: 0.875rem;
        line-height: 1.25rem;
        bottom: 1.25rem;
        padding: 1.5rem 0 0;
        max-width: 84.325rem;
        left: 50%;
        width: 100%;
        max-height: fit-content;
        transform: translateX(-50%);
    }

    @include respond-max-width-breakpoint(compact) {
        max-height: 100%;
        width: 100%;
        position: fixed;
        transform: none;
        top: 0;
        height: 100%;
        left: 0;
        margin: 0;
        border-radius: 0px;

        &--enter-animation {
            animation-name: fade-in;
            animation-duration: 0.2s;
            animation-fill-mode: forwards;
        }

        &--exit-animation {
            animation-name: fade-out;
            animation-duration: 0.2s;
            animation-fill-mode: forwards;
        }

        &--small {
            top: auto;
            height: auto;
            bottom: 0;
            padding: 1rem;
            justify-content: space-between;
            border-radius: 2rem 2rem 0 0;

            &--enter-animation {
                animation-name: slide-up;
                animation-duration: 0.3s;
                animation-fill-mode: forwards;
            }

            &--exit-animation {
                animation-name: slide-down;
                animation-duration: 0.3s;
                animation-fill-mode: forwards;
            }
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap-reverse;
        align-items: center;
        gap: 1rem;
        padding: 2rem 2rem 0 2rem;
        word-break: break-word;
        hyphens: auto;

        @include respond-max-width-breakpoint(compact) {
            padding: 1rem;
        }

        &__title {
            margin: 0;
        }

        &__close-button {
            display: flex;
            padding: 0.75rem;

            &__icon {
                width: 1rem;
                height: 1rem;
            }
        }
    }

    &__header-close {
        display: flex;
        flex: 1;
        justify-content: flex-end;
    }

    &__content-scroll {
        overflow: auto;
        padding: 2rem;

        @include respond-max-width-breakpoint(compact) {
            padding: 1rem 1rem 2.5rem;
        }

        &--has-header {
            padding: 1rem 2rem 2rem;
            @include respond-max-width-breakpoint(compact) {
                padding: 1rem 1rem 2.5rem;
            }
        }
    }

    &__content {
        font-size: 1.125rem;

        h1 {
            font-size: 2.5rem;
            line-height: 115%;
            margin-bottom: 1.5rem;
        }

        p {
            line-height: 150%;
        }

        .error_modal {
            font-size: 1rem;

            @include respond-max-width-breakpoint(medium) {
                width: auto;
            }
        }
    }

    &__action-buttons {
        padding: 0 2.5rem 2.5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        gap: 12px;

        @include respond-max-width-breakpoint(compact) {
            flex-direction: column;
            padding: 1rem 1rem 1rem;
        }

        &__button {
            width: 100%;
        }
    }
}

.show-fade {
    animation-name: fade-in;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
}

.hide-fade {
    animation-name: fade-out;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
}
