@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.dropin-people-form {
    &__patient-form-list {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 3rem;
    }

    &__people-footer {
        margin: 3rem 2rem 0;

        @include respond-max-width-breakpoint(medium) {
            margin: 3rem 0 0;
        }
    }

    &__sub-heading {
        font-size: 1.125rem;
        font-family: 'RubikVariable';
        font-variation-settings: 'wght' 600;
        font-weight: 600;
        line-height: 1.625rem;
        margin-bottom: 0.5rem;
    }

    &__add-person-button {
        display: inline-flex;
        align-items: center;
        gap: 1rem;
        font-size: 1.125rem;
        padding: 0.5rem;
        margin-left: -0.5rem;
    }

    .form-container {
        margin-top: 0;
    }
}
