@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.clinic-form {
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
    @include respond-max-width-breakpoint(medium) {
        margin-right: 0;
    }
}
.select-container {
    position: relative;

    .select-with-label {
        pointer-events: none;
        position: absolute;
        top: 15px;
        left: 32px;
        font-size: min(max(0.875rem, 1vw), 1rem);

        @include respond-max-width-breakpoint(medium) {
            font-size: 0.875rem;
        }
    }
    .select {
        width: 100%;
        position: relative;
        font-family: inherit;
        color: $primaryTextColor;
        border: 1px solid #d4d4d4;
        border-radius: 50px;
        padding: 27px 60px 27px 32px;
        font-size: 1.375rem;
        font-size: min(max(1rem, 1.5vw), 22px);
        background-color: transparent;
        background-image: url('../../../icons/icon_dropdown_32.svg');
        background-repeat: no-repeat;
        background-position: calc(100% - 1rem) center;
        appearance: none;

        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;

        &:disabled {
            opacity: 0.3;
            &:hover {
                cursor: auto;
                background-color: transparent;
            }
        }

        &.has-selection {
            padding: 36px 60px 18px 32px;
        }
        &:hover {
            cursor: pointer;
            background-color: rgba($primaryAccentColor, 0.1);
        }

        &:focus-visible {
            outline: #121212 auto 1px;
        }

        @include respond-max-width-breakpoint(medium) {
            width: 100%;
            font-size: 1.125rem;
        }
    }

    &__info {
        color: #757575;
        margin: 0.5rem 0 0.5rem 2rem;
    }

    @media (max-width: 15.625rem) {
        .select-with-label {
            position: static;
        }
        .select {
            margin-top: 0.5rem;
            &.has-selection {
                padding: 1rem;
                padding-right: 3.75rem;
            }
        }
    }
}

.amount-input {
    margin: 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d4d4d4;
    border-radius: 50px;
    padding: 15px 16px 15px 32px;
    font-size: 1.375rem;
    font-size: min(max(1rem, 1.5vw), 22px);
    font-weight: 500;
    appearance: none;

    &__info {
        color: #757575;
        margin-bottom: 24px;
        margin-left: 32px;
        margin-top: -8px;
    }
    &__label {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include respond-max-width-breakpoint(medium) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    &__label__content {
        display: flex;
        align-items: center;

        @include respond-max-width-breakpoint(medium) {
            width: 100%;
            justify-content: space-between;
            margin-top: 1rem;
        }
        &__input {
            margin: 0 1px;
            width: 68px;
            height: 100%;
            border: none;
            font-family: inherit;
            font-size: 1.375rem;
            padding: 10px 0;
            font-weight: 500;
            text-align: center;
            pointer-events: none;
            @include respond-max-width-breakpoint(medium) {
                font-size: 1.125rem;
            }

            @include respond-max-width-breakpoint(small) {
                max-width: 40px;
            }
        }
        .amount-button {
            border-radius: 25px;
            padding: 9px;
            max-height: 50px;
            background-color: transparent;

            &:hover {
                background-color: rgba($primaryAccentColor, 0.1);
            }

            svg {
                color: $primaryAccentColor;
                width: 32px;
                height: 32px;
            }

            &:disabled {
                svg {
                    color: rgba(18, 18, 18, 0.3);
                }
                @include respond-max-width-breakpoint(medium) {
                    background-color: transparent;
                }
                &:hover {
                    background-color: transparent;
                    cursor: auto;
                }
            }

            &:focus-visible {
                outline: #121212 auto 3px;
            }

            @include respond-max-width-breakpoint(medium) {
                height: 50px;
                width: 100px;
                padding: 0.5rem;
                background-color: rgba(227, 0, 49, 0.04);
            }
        }
    }
    @include respond-max-width-breakpoint(medium) {
        width: 100%;
        font-size: 1.125rem;
        flex-direction: column;
        align-items: flex-start;
        padding: 18px 1.5rem;
        border-radius: 30px;
    }
}

.dropin-info {
    padding: 0;
    margin-bottom: 1rem;
    font-size: 1.125rem;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.clinic-form Button.btn {
    min-width: 128px;
    height: 60px;
    margin-top: 1.5rem;
    font-size: 1.125rem;
    font-variation-settings: 'wght' 600;
    align-self: flex-end;

    &:disabled {
        background-color: rgba(18, 18, 18, 0.05);
        color: rgba(18, 18, 18, 0.3);
    }

    @include respond-max-width-breakpoint(medium) {
        width: 100%;
    }
}
