@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.main-content--information {
    display: flex;
    flex-direction: column;
    max-width: 600px;

    @include respond-max-width-breakpoint(medium) {
        max-width: none;
        min-width: auto;
    }

    &__header {
        font-size: 2.5rem;
        line-height: 1.1;
        margin-bottom: 1rem;
    }
    &-container {
        padding-bottom: 26px;

        &__text {
            margin-bottom: 1rem;
            line-height: 1.5;
            font-size: 1.125rem;
            white-space: pre-wrap;

            @include respond-max-width-breakpoint(medium) {
                font-size: 1rem;
            }
        }
    }

    &__button {
        margin-top: 0.5rem;
        width: fit-content;
        font-size: 1.125rem;
        font-variation-settings: 'wght' 600;
        line-height: 1.5;
        padding: 1.25rem 2rem;

        @include respond-max-width-breakpoint(medium) {
            width: inherit;
            font-size: 1rem;
            padding: 1rem 2rem;
        }
    }
}
