@import '../../../scss/variables';
@import '../../../scss/mixins';

.input-container {
    position: relative;

    .input-error-label {
        visibility: hidden;
    }

    [aria-invalid='true'] ~ .input-error-label {
        visibility: visible;
        position: relative;
        display: inline-block;
        font-size: 0.875rem;
        top: 0.3rem;
        color: $primaryColor;

        @media (max-width: 15.625rem) {
            padding: 0;
        }
    }

    .input-with-label {
        pointer-events: none;
        position: absolute;
        top: 28px;
        left: 32px;
        font-size: 1.125rem;
        color: $gray600;
        z-index: 1;
        transition: all 0.2s ease-in-out;

        &.has-content {
            top: 16px;
            font-size: 0.875rem;
            color: $gray600;
            transition: all 0.2s ease-in-out;
        }

        @include respond-max-width-breakpoint(medium) {
            top: 30px;
            font-size: 1.125rem;
        }
    }
    &:focus-within {
        .input-with-label {
            top: 16px;
            font-size: 0.875rem;
            color: $black;
            transition: all 0.2s ease-in-out;
        }
    }

    .input {
        width: 100%;
        position: relative;
        font-family: inherit;
        color: $black;
        -webkit-appearance: none;
        appearance: none;
        border: none;
        box-shadow: 0 0 0 1px $gray300 inset;
        border-radius: 40px;
        padding: 35px 60px 20px 32px;
        font-size: 1.125rem;
        line-height: 25px;
        background-color: transparent;

        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;

        &.invalid {
            box-shadow: 0 0 0 2px $red inset;
        }

        &:disabled {
            color: $gray600;

            @media (hover: hover) {
                &:hover {
                    cursor: auto;
                    background-color: transparent;
                }
            }
        }

        @media (hover: hover) {
            &:hover {
                cursor: pointer;
            }
        }

        &:focus-visible {
            outline: none;
            box-shadow: 0 0 0 2px $black inset;
            &::placeholder {
                opacity: 1;
            }
        }

        &::placeholder {
            color: $gray600;
            opacity: 0;
            transition: all 0.2s ease;
        }

        @include respond-max-width-breakpoint(medium) {
            width: 100%;
            font-size: 1.125rem;
        }
    }

    @media (max-width: 15.625rem) {
        .input-with-label {
            position: static;
        }
        .input {
            margin-top: 0.5rem;
            border-radius: 12px;
        }
    }
}
