@use 'sass:color';

@import 'animations';
@import 'variables';
@import 'mixins';
@import 'utils';
@import 'typography';
@import 'grid';
@import 'reboot';

@import '@fontsource/rubik';
@import '@fontsource/rubik/variable.css';

.page-container-margin {
    margin: 2rem 2rem;
    @include respond-max-width-breakpoint(compact) {
        margin: 2rem 1rem;
    }
}

.off-screen {
    position: absolute;
    left: -9999px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.no-hyphens {
    hyphens: none;
}

.leaflet-control-attribution {
    display: none;
}

.mobile-only {
    display: none;

    @include respond-max-width-breakpoint(medium) {
        display: block;
    }
}
