@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.wrap-html {
    white-space: pre-wrap;
}

.main-content--initial {
    &__header {
        line-height: 1.1;
        margin-top: 1.8rem;
    }
}

.main-content {
    flex: 1;
    padding-top: 3rem;
    grid-area: main;
    margin-right: 40%;
    min-width: 450px;

    @include respond-max-width-breakpoint(medium) {
        margin-right: 0;
        min-width: auto;
    }

    &__header {
        max-width: 790px;
        font-size: 2.5rem;
        margin-bottom: 1rem;

        @include respond-max-width-breakpoint(medium) {
            font-size: 2.125rem;
        }

        &__error {
            font-size: 3.125rem;
            font-size: min(max(34px, 3vw), 50px);
            font-variation-settings: 'wght' 400;
        }
    }
    &__welcome {
        margin-top: 40px;
        max-width: 790px;
        font-size: 1.125rem;
        line-height: 1.75rem;
    }
    .main-content__section button {
        font-size: 1.25rem;
        padding: 20px 32px;

        @include respond-max-width-breakpoint(medium) {
            margin-top: 2rem;
            width: 100%;
        }
    }
    .nav-container {
        margin: 5rem 0 3rem;
        list-style: none;
        display: flex;
        align-items: center;

        @include respond-max-width-breakpoint(medium) {
            margin: 40px 0;
        }

        :nth-child(2) {
            margin-left: 2rem;
        }

        &--no-selection .tab-option:first-child:not(.selected) {
            color: $primaryAccentColor;
            transition: all 0.3s;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: -1rem;
                width: 100%;
                height: 5px;
                background-color: $primaryAccentColor;
                border-radius: 2px;
                box-shadow: 0px 40px 11px rgba(102, 0, 20, 0.01), 0px 25px 10px rgba(102, 0, 20, 0.04),
                    0px 14px 9px rgba(102, 0, 20, 0.15), 0px 6px 6px rgba(102, 0, 20, 0.26),
                    0px 2px 4px rgba(102, 0, 20, 0.29), 0px 0px 0px rgba(102, 0, 20, 0.3);
                transition: all 0.3s;
            }
        }

        .tab-option {
            font-size: 1.875rem;
            position: relative;
            line-height: 1.875rem;
            letter-spacing: -0.01em;
            color: $primaryTextColor;
            font-variation-settings: 'wght' 600;

            &:hover {
                color: $primaryAccentColor;
            }

            &:focus-visible {
                outline: #121212 auto 1px;
            }

            &.selected {
                color: $primaryAccentColor;
                transition: all 0.3s;

                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -1rem;
                    width: 100%;
                    height: 5px;
                    background-color: $primaryAccentColor;
                    border-radius: 2px;
                    box-shadow: 0px 40px 11px rgba(102, 0, 20, 0.01), 0px 25px 10px rgba(102, 0, 20, 0.04),
                        0px 14px 9px rgba(102, 0, 20, 0.15), 0px 6px 6px rgba(102, 0, 20, 0.26),
                        0px 2px 4px rgba(102, 0, 20, 0.29), 0px 0px 0px rgba(102, 0, 20, 0.3);
                    transition: all 0.3s;
                }

                &.booking::after {
                    animation: slideInBooking 0.3s ease;
                }

                &.drop-in::after {
                    animation: slideInDropin 0.3s ease;
                }
            }
        }
    }
}

@keyframes slideInBooking {
    0% {
        transform: translateX(125px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideInDropin {
    0% {
        transform: translateX(-131px);
    }
    100% {
        transform: translateX(0);
    }
}
.skeleton-box {
    margin-top: 2.5rem;
    max-width: 790px;
    width: 80%;
    height: 50px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    background-color: rgba(#121212, 0.05);
    border-radius: 25px;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        transform: translateX(-100%);
        background-image: linear-gradient(
            90deg,
            rgba(#121212, 0) 0,
            rgba(#121212, 0.02) 20%,
            rgba(#121212, 0.05) 60%,
            rgba(#121212, 0)
        );
        animation: shimmer 2s infinite;
        content: '';
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}
