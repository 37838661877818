@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.health-declaration {
    word-break: normal;
    hyphens: initial;

    @media (max-width: 15.625rem) {
        word-break: break-word;
        hyphens: auto;
    }

    &__people-section {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 3.125rem;
    }

    &__travel-section {
        margin: 2.5rem 2rem 0;

        @media (max-width: 15.625rem) {
            margin: 2.5rem 0 0;
        }
    }

    &__next-section {
        margin-top: 4.625rem;
        display: flex;
        justify-content: flex-end;
    }

    &__next-button {
        @include respond-max-width-breakpoint(medium) {
            flex: 1;
        }
    }

    &__sub-heading {
        font-size: 1.125rem;
        font-family: 'RubikVariable';
        font-variation-settings: 'wght' 600;
        font-weight: 600;
        line-height: 1.625rem;
        margin-bottom: 0.5rem;
    }

    &__person-info {
        display: flex;
        flex-direction: column;
        font-size: 1.25rem;
        line-height: 1.625rem;
        letter-spacing: -0.01rem;

        @include respond-max-width-breakpoint(medium) {
            font-size: 1.125rem;
        }
    }

    &__person-name {
        font-family: 'RubikVariable';
        font-variation-settings: 'wght' 700;
    }

    &__btn {
        line-height: 1.5rem;
        padding: 0.8rem 2rem;
        font-size: 1rem;
        white-space: nowrap;

        @include respond-max-width-breakpoint(medium) {
            width: 100%;
        }
    }

    &__person {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        border-radius: 2.5rem;
        border: 1px solid rgba(18, 18, 18, 0.15);
        padding: 1.5rem 2rem;

        @include respond-max-width-breakpoint(medium) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__add-travel-link {
        display: inline-flex;
        align-items: center;
        gap: 1rem;
        font-size: 1.125rem;
    }

    &__destination-list {
        display: flex;
        flex-direction: column;
        font-size: 1.125rem;
        line-height: 150%;
        list-style: none;
        margin-bottom: 0.5rem;
        gap: 0.5rem;
    }

    &__header {
        @include respond-max-width-breakpoint(medium) {
            font-size: 2.125rem;
        }
        @include respond-max-width-breakpoint(small) {
            overflow-wrap: break-word;
            word-break: break-word;
            hyphens: auto;
        }
    }

    &__header-person {
        font-family: 'RubikVariable';
        font-variation-settings: 'wght' 400;
    }

    &__question {
        margin-top: 1.5rem;

        // &--followup {
        // }
    }

    &__question-footer {
        margin-top: 2.5rem;
    }
}

// Extreme zoom level adjustments
@media (max-width: 15.625rem) {
    .health-declaration {
        &__header,
        &__question {
            overflow-wrap: break-word;
            word-break: break-word;
            hyphens: auto;
        }

        &__person {
            padding: 1rem;
        }
    }
}
