@use 'sass:color';
@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.time-slot-picker {
    padding-right: 16px;

    &__date-row {
        display: flex;
        flex-direction: column;
        transition: border-color 0.5s ease;
        border-bottom: 1px solid transparent;

        position: sticky;
        top: 60px;
        z-index: 1;
        background: #fff;

        .date-picker-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .date-input-native {
                border: none;
                border-bottom: 1px solid $primaryAccentColor;
                background: transparent;
                border-radius: 0;
            }

            .date-input-button,
            .date-input-native {
                text-transform: capitalize;
                display: flex;
                gap: 8px;
                align-items: center;
                font-size: 1rem;

                font-family: 'RubikVariable';
                font-variation-settings: 'wght' 500;
                color: $primaryAccentColor;
                text-decoration: underline;
                cursor: pointer;

                svg {
                    margin-left: 0.5rem;
                }

                @media (hover: hover) {
                    &:hover {
                        color: color.scale($primaryAccentColor, $lightness: -20%);
                    }
                }

                &:focus-visible {
                    outline: 2px solid #121212;
                }
            }

            .date-input-button {
                span {
                    display: flex;
                }
            }

            .day-week-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 60px;

                @include respond-max-width-breakpoint(medium) {
                    &__today-button {
                        display: none;
                    }
                }

                &__today-button {
                    color: $primaryAccentColor;
                    line-height: 1.375rem;
                    text-decoration-line: underline;
                    font-size: 1rem;

                    &:focus-visible {
                        outline: 2px solid #121212;
                    }

                    @media (hover: hover) {
                        &:hover {
                            color: color.scale($primaryColor, $lightness: -20%);
                        }
                    }
                }

                .week-container {
                    display: flex;
                    gap: 16px;
                    align-items: center;
                    &__icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 44px;
                        height: 44px;
                        border-radius: 50%;
                        transition: background-color 0.2s ease;
                        position: relative;
                        cursor: auto;

                        &--available {
                            cursor: pointer;
                            background: rgba(227, 0, 49, 0.04);

                            @media (hover: hover) {
                                &:hover {
                                    background: rgba(227, 0, 49, 0.1);
                                }
                            }

                            svg * {
                                stroke-opacity: 1;
                                stroke: $primaryAccentColor;
                            }

                            &:focus-visible {
                                outline: none;

                                &::before {
                                    content: '';
                                    position: absolute;
                                    top: -2px;
                                    right: -2px;
                                    bottom: -2px;
                                    left: -2px;
                                    border: 3px solid #121212;
                                    border-radius: 50%;
                                    z-index: 400;
                                }
                            }

                            @supports not selector(:focus-visible) {
                                &:focus {
                                    /* Fallback for browsers without :focus-visible support */
                                    outline: 3px solid #121212;
                                }
                            }
                        }

                        &--right {
                            svg {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
        }

        .dates-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 12px 0;
            gap: 10px;

            @include respond-max-width-breakpoint(medium) {
                display: none;
            }

            .day-number {
                display: flex;
                flex-direction: column;
                font-family: 'RubikVariable';
                width: 150px;
                pointer-events: none;

                &__day {
                    font-variation-settings: 'wght' 400;
                    font-size: 1rem;
                    line-height: 24px;
                    transition: all 0.5s ease;
                    align-self: flex-start;
                    text-transform: capitalize;
                }

                &__date {
                    font-variation-settings: 'wght' 300;
                    font-size: min(max(1rem, 6vw), 80px);
                    line-height: min(max(1rem, 6vw), 95px);
                    transition: all 0.5s ease;

                    &--disabled {
                        position: relative;
                        &::after {
                            content: '';
                            width: 100%;
                            height: 3px;
                            background: #121212;
                            position: absolute;
                            top: 50%;
                            left: 0;
                        }
                    }
                }

                &--selected {
                    .day-number__day {
                        font-variation-settings: 'wght' 600;
                    }
                    .day-number__date {
                        font-variation-settings: 'wght' 700;
                    }
                }

                &--disabled {
                    opacity: 0.3;

                    .day-number__day {
                        text-decoration-line: line-through;
                    }
                    .day-number__day {
                        text-decoration-line: line-through;
                    }
                }
            }
        }
    }

    &__time-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
        margin-top: 12px;

        @include respond-max-width-breakpoint(medium) {
            flex-direction: column;
            margin-top: 0;
        }

        .time-slot-column-wrapper {
            display: flex;
            flex-direction: column;
            flex: 1;
        }
        .time-slot-column {
            display: flex;
            flex-direction: column;
            flex: 1;
            max-width: 150px;
            gap: 4px;

            list-style-type: none;

            @include respond-max-width-breakpoint(medium) {
                max-width: none;
                flex-direction: column;
            }

            &:first-child {
                .time-slot-column__day {
                    margin-top: 0;
                }
            }

            &__list-item {
                display: flex;
            }

            &__day {
                display: none;

                @include respond-max-width-breakpoint(medium) {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-family: 'RubikVariable';
                    margin: 1.5rem 0 1rem;

                    &__date {
                        font-size: 1.5rem;
                        line-height: 1.875rem;
                    }
                    &__day {
                        text-transform: capitalize;
                    }

                    &--disabled {
                        opacity: 0.3;
                        margin-bottom: 0;
                        .time-slot-column__day__date {
                            text-decoration-line: line-through;
                        }
                    }

                    &--selected {
                        .time-slot-column__day__day {
                            font-variation-settings: 'wght' 600;
                        }
                        .time-slot-column__day__date {
                            font-variation-settings: 'wght' 700;
                        }
                    }
                }
            }

            .time-slot {
                color: #121212;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex: 1;
                position: relative;
                padding: 1rem;
                font-variation-settings: 'wght' 400;
                border-radius: 50px;
                border: 1px solid rgba(18, 18, 18, 0.15);
                line-height: 1.33;
                font-size: 1.125rem;
                white-space: nowrap;
                -webkit-tap-highlight-color: transparent;
                transition: background-color 0.2s ease, border-color 0.2s ease, box-shadow 0.2s ease,
                    transform 0.1s ease;

                span {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex: 1;
                }

                @media (min-width: 821px) and (max-width: 1024px) {
                    font-size: 0.8rem;
                    padding: 0.7rem;
                }

                &:disabled:not(.time-slot--selected) {
                    cursor: auto;

                    @media (hover: hover) {
                        &:hover {
                            background: transparent;
                        }
                    }
                }

                @media (hover: hover) {
                    &:hover {
                        background: rgba(227, 0, 49, 0.1);
                    }
                }

                &:active {
                    transform: scale(0.95);
                }

                &:focus-visible {
                    outline: none;

                    &::before {
                        content: '';
                        position: absolute;
                        top: -2px;
                        right: -2px;
                        bottom: -2px;
                        left: -2px;
                        border: 3px solid #121212;
                        border-radius: 50px;
                        z-index: 0;
                    }
                }

                @supports not selector(:focus-visible) {
                    &:focus {
                        /* Fallback for browsers without :focus-visible support */
                        outline: 3px solid #121212;
                    }
                }

                &--previous-slot {
                    border-color: transparent;
                    box-shadow: 0 0 0 2px $primaryColor inset;
                }

                &--selected {
                    background: $primaryAccentColor;
                    border: 1px solid $primaryAccentColor;
                    box-shadow: none;
                    color: #fff;

                    &:disabled {
                        pointer-events: none;
                    }

                    @media (hover: hover) {
                        &:hover {
                            background: color.scale($primaryColor, $lightness: -20%);
                            border-color: color.scale($primaryColor, $lightness: -20%);
                        }
                    }
                }
            }

            &__no-free {
                display: none;
                @include respond-max-width-breakpoint(medium) {
                    display: block;
                }
            }
        }
    }

    &__error {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 3.5rem;

        > p {
            line-height: 1.625rem;
            text-align: center;
        }

        &__header {
            font-family: 'RubikVariable';
            font-variation-settings: 'wght' 600;
        }

        &__button {
            margin-top: 2rem;
            button {
                padding: 16px 32px;
            }
        }
    }

    &--scrolled {
        .time-slot-picker__date-row {
            border-bottom: 1px solid rgba(18, 18, 18, 0.15);

            .dates-container {
                .day-number {
                    &__day {
                        font-size: 1rem;
                    }

                    &__date {
                        font-size: min(max(1rem, 3vw), 40px);
                        line-height: 47px;
                        align-self: flex-start;
                    }
                }
            }
        }
    }

    @include respond-max-width-breakpoint(medium) {
        padding-right: 0;

        &__date-row {
            border-bottom: 1px solid rgba(18, 18, 18, 0.15);
            padding-bottom: 0.5rem;
        }
    }
}
