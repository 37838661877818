@import '../../../../../scss/variables';
@import '../../../../../scss/mixins';

.consent-header-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;

    position: sticky;
    z-index: 2;
    top: 0;
    background-color: #fff;

    &:empty {
        visibility: hidden;
    }

    .consent-button {
        color: $primaryAccentColor;
        font-variation-settings: 'wght' 400;
        font-size: 1rem;
        line-height: 1.5;
    }
    .logo {
        justify-self: flex-start;
    }
}
