@import 'src/scss/variables';

.vaccine-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 600px;
    list-style-type: none;

    &__date-header {
        font-size: 1rem;
        line-height: 1.3125rem;
        font-variation-settings: 'wght' 500;
        margin-top: 1rem;
        margin-bottom: 0;

        &:first-of-type {
            margin-top: 0;
        }
    }
}
