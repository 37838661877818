@import '../../../scss/mixins';

.map_active_area {
    // box-shadow: inset 0px 0px 0px 10px #f00;
    // z-index: 500;

    height: calc(100vh - 60px);
    height: calc(100svh - 92px);
    pointer-events: none;
    position: absolute;
    top: 16px;
    bottom: 0;
    left: 375px;
    right: 16px;

    @include respond-max-width-breakpoint(small) {
        height: calc(100vh - 215px);
        height: calc(100svh - 215px);
        left: 0;
        right: 0;
        bottom: 269px;
    }
}

.search_modal--hide + .map_container {
    .map_active_area {
        @include respond-max-width-breakpoint(small) {
            height: calc(100vh - 92px);
            height: calc(100svh - 92px);
            left: 0;
            right: 0;
            bottom: 269px;
        }
    }
}

.main--list-modal-visible {
    .map_active_area {
        right: 382px;

        @include respond-max-width-breakpoint(medium) {
            right: 0;
        }
    }
}

.map_container {
    background: #aad3df;
    width: 100vw;
    width: 100svw;
    z-index: 0;
    height: calc(100vh - 60px);
    height: calc((var(--vhfallback, 1vh) * 100) - 60px);
    height: calc(100svh - 60px);

    &:focus-visible {
        outline: 3px solid #121212;
        outline-offset: 0px;
    }

    @supports not selector(:focus-visible) {
        &:focus {
            /* Fallback for browsers without :focus-visible support */
            outline: none;
        }
    }

    .leaflet-popup-content {
        img {
            height: 20px;
            display: block;
        }
    }
}
