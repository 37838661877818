@import 'src/scss/variables';
@import 'src/scss/mixins';

/* font-size * line-height + margin */
$error-label-bottom-margin: calc(0.875rem * 1.5 + 0.25rem);

/* font-size * line-height */
$input-container-height: calc(1.125rem * 1.25);

.date-select-container {
    container-type: inline-size;
    container-name: date-select-container;

    &__error-label {
        position: relative;
        display: inline-block;
        font-size: 0.875rem;
        line-height: 1.5;
        color: $primaryColor;
        margin: 0.5rem 33px 0;
    }
}

.calendar-icon {
    height: 24px;
    width: 24px;
    margin-right: 0.2rem;
    margin-left: 0.5rem;
    pointer-events: none;

    @include respond-max-width-breakpoint(compact) {
        margin-right: 0.5rem;
    }
}

.calendar-icon-container {
    display: inline-block;
    position: relative;
    align-self: center;
    pointer-events: none;
    @container date-select-container (max-width: 233px) {
        display: none;
    }
}

.date-select {
    width: 100%;
    scroll-margin: 2rem;

    &__container {
        width: 100%;
    }

    .react-datepicker__navigation {
        top: calc(1.5rem - 2px); // - 2px is to account for border radius

        &--next {
            right: 1.5rem;
        }

        &--previous {
            left: 1.5rem;
        }
    }

    .react-datepicker__input-container {
        display: flex;
    }

    .react-datepicker-wrapper {
        width: inherit;
    }

    &.invalid {
        margin-bottom: $error-label-bottom-margin;
    }

    &__native {
        display: flex;
        font-family: 'Rubik';
        border: 1px solid #d4d4d4;
        border-radius: 40px;
        padding: 17.5px 18px 17.5px 32px;

        hyphens: auto;
        word-wrap: break-word;
        flex-direction: row;
        justify-content: space-between;

        @container date-select-container (max-width: 180px) {
            padding: 17.5px 10px 17.5px 10px;
        }

        &.invalid {
            margin-top: 0.5rem;
            box-shadow: 0 0 0 1px $primaryColor inset;
            border: 1px solid $primaryColor;
        }

        &__left-column {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: fit-content;
            align-items: flex-start;
        }

        &__label {
            padding: 0 1.5rem 0 0;
            font-size: 0.875rem;
            line-height: 1.25rem;
        }

        &__input {
            font-family: 'Rubik';
            font-size: 1.125rem;
            line-height: 1.25;
            color: $primaryTextColor;
            background-color: $primaryBackgroundColor;
            border: none;
            padding: 0;
            height: $input-container-height;
            max-width: fit-content;

            appearance: none;
            -webkit-appearance: none;

            &::-webkit-date-and-time-value {
                text-align: left;
            }

            &::-webkit-calendar-picker-indicator {
                opacity: 0;
            }

            &:valid,
            &:active {
                background: #fff;
            }
        }

        &:active {
            outline: none;
        }

        &:focus {
            outline: none;
            box-shadow: 0 0 0 2px #121212;
        }

        &:focus-visible {
            outline: 2px solid #121212;
        }
    }

    &__header {
        margin: 0.5rem;
        display: flex;
        align-content: center;
        justify-content: center;
        gap: 0.5rem;
    }

    &__button {
        font-family: 'Rubik';
        font-size: 1.125rem;
        line-height: 1.25;
        color: $primaryTextColor;
        border: 1px solid #d4d4d4;
        border-radius: 40px;
        padding: 27.5px 24px 27.5px 32px;
        text-align: left;
        display: flex;
        flex-direction: row;
        width: 100%;
        flex: 1;
        justify-content: space-between;
        align-items: center;

        appearance: none;
        white-space: nowrap;

        &.invalid {
            box-shadow: 0 0 0 1px $primaryColor inset;
            border: 1px solid $primaryColor;
        }

        &--has-value {
            padding-top: 17.5px;
            padding-bottom: 17.5px;

            .date-select__button__content {
                display: flex;
                flex-direction: column;

                &__label {
                    color: $primaryTextColor;
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                }
            }
        }

        &__content__label {
            font-size: 1.125rem;
            line-height: 1.25rem;
            color: #757575;
        }

        &:focus-visible {
            outline: #121212 auto 1px;
        }

        &:hover {
            cursor: pointer;
            background-color: #fbdce3;
        }
    }
}
