@import '../../../../scss/variables';

.history-info-item-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem;
    background-color: #fef5f7;
    border-radius: 1rem;

    &__icon {
        color: $primaryAccentColor;
        margin-bottom: 0.5rem;
    }

    &__header {
        font-size: 1.125rem;
        margin-bottom: 0.5rem;
    }
}
