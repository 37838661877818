@import '../../../scss/variables';
@import '../../../scss/mixins';

.policy-header-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;

    position: sticky;
    z-index: 2;
    top: 0;
    background-color: #fff;

    .logo {
        justify-self: flex-start;
    }
}
