@import '../../scss/variables';
@import '../../scss/mixins';

.terms-and-conditions-container {
    display: flex;
    flex-direction: column;

    &__actions {
        margin-top: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &__button {
            padding: 1rem 2rem;
            font-size: 1rem;
            margin-top: 2.6875rem;
        }

        &__toggle {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 1rem;

            &__button {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                &__error {
                    display: none;
                    font-size: 0.875rem;
                    color: $primaryColor;
                    margin-top: 0.5rem;
                }
            }
        }
    }
}
