@import '../../../../scss/variables';

.main-content.completed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.completed {
    .header {
        margin-top: 2rem;
        font-size: 2.5rem;
    }
    .subheader {
        font-size: 1.125rem;
        margin-bottom: 2rem;
    }
    .link-section {
        margin-top: 2rem;
        align-self: flex-start;

        .button-link {
            display: flex;
            align-items: center;
            text-decoration: underline;
            color: $primaryAccentColor;
            position: relative;
            font-size: 1rem;

            > svg {
                margin-right: 0.5rem;
            }

            &:focus-visible {
                outline: none;

                &::before {
                    content: '';
                    position: absolute;
                    top: -4px;
                    right: -5px;
                    bottom: -4px;
                    left: -4px;
                    border: 3px solid #121212;
                    border-radius: 40px;
                    z-index: 400;
                }
            }
            @supports not selector(:focus-visible) {
                &:focus {
                    /* Fallback for browsers without :focus-visible support */
                    outline: 3px solid #121212;
                }
            }
        }
    }
}
