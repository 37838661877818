@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.booking-item {
    width: 100%;
    max-width: 645px;
    background-color: rgba(227, 0, 49, 0.04);
    padding: 3.125rem 0 0;
    border-radius: 3.125rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include respond-max-width-breakpoint(medium) {
        padding: 2rem 0 0;
    }

    &:only-of-type {
        margin-top: 3.125rem;
        max-width: 645px;
    }

    &__change-button {
        display: flex;
        font-size: 1rem;
        padding: 0.3125rem 1rem;
        white-space: nowrap;
        align-self: flex-start;

        margin-left: 2rem;

        @include respond-max-width-breakpoint(medium) {
            margin-left: 1.5rem;
        }

        @include respond-max-width-breakpoint(x-small) {
            hyphens: auto;
            white-space: normal;
            text-align: left;
        }
    }

    &__cancel-button {
        display: flex;
        font-size: 1rem;
        padding: 0 0.5rem;
        margin-left: -0.5rem;
        line-height: 1.5;
        border-radius: 0;
    }

    &__title {
        font-size: 1.5rem;
        padding: 0 42px;
        @include respond-max-width-breakpoint(medium) {
            padding: 0 1.5rem;
        }
    }

    &__clinic-logo {
        border-radius: 0.5rem;
        padding: 0.5rem;
        border-radius: 0.5rem;
        background: #fff;
        margin: 0 0 0 3rem;
        @include respond-max-width-breakpoint(medium) {
            margin-left: 2.5rem;
        }

        img {
            display: block;
            height: 1.875rem;

            @include respond-max-width-breakpoint(x-small) {
                height: 1rem;
            }
        }
    }

    &__list {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        padding: 1rem 42px 1.75rem 42px;
        @include respond-max-width-breakpoint(medium) {
            padding: 0 1.5rem 1.75rem 1.5rem;
        }

        &__item {
            display: flex;
            align-items: center;
            gap: 1rem;
            border-radius: 40px;
            justify-content: space-between;

            svg {
                color: #e30031;
                min-width: 24px;
                align-self: flex-start;
                margin-top: 4px;
            }
            span {
                display: block;
            }
            .people {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .name {
                    display: block;
                    font-family: 'RubikVariable';
                    font-variation-settings: 'wght' 500;
                }
            }

            @include respond-max-width-breakpoint(medium) {
                flex-direction: column;
                align-items: flex-start;
                gap: 0;
            }

            &--location,
            &--consent {
                flex-direction: column;
                gap: 0.5rem;
                align-items: flex-start;
            }
        }
        &__item-content {
            display: flex;
            align-items: center;
            gap: 1.5rem;
            line-height: 1.5;

            @include respond-max-width-breakpoint(medium) {
                gap: 1rem;
                word-break: break-word;
                hyphens: auto;
            }
        }
    }
    &__footer {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 1.5rem 42px;
        .cancel_header {
            font-family: 'RubikVariable';
            font-variation-settings: 'wght' 500;
            line-height: 1.5rem;
        }
    }
}

.capitalize {
    text-transform: capitalize;
}
