@import '../../../scss/mixins';

.loading_indicator {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 40px;
    height: 40px;
    top: 16px;
    padding: 8px;
    background: #fff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;

    & > div {
        height: 24px;
        width: 24px;
        display: flex;
    }

    @include respond-max-width-breakpoint(small) {
        top: 76px;
        left: 16px;
        transform: none;
    }
}

.loading_indicator-enter {
    opacity: 0;
}

.loading_indicator-enter-active {
    opacity: 1;
    transition: opacity 200ms;
}

.loading_indicator-exit {
    opacity: 1;
}

.loading_indicator-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}
