@import 'src/scss/variables';

.clinic-list-item {
    display: flex;
    flex-direction: column;
    margin: 0 0.5rem;
    padding: 1rem;
    border-radius: 1.5rem;

    &__content {
        font-size: 0.875rem;
        color: $gray700;
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        margin-top: 0.25rem;

        &__text-container {
            display: flex;
            flex-wrap: wrap;
            row-gap: 0.25rem;
            flex-direction: column;
        }
    }

    &__clinic-name {
        line-height: 1.5rem;
        overflow-wrap: break-word;
        hyphens: auto;
    }

    &__link {
        margin-top: 0.625rem;
        .btn {
            padding: 11px 20px;
        }

        @media (hover: hover) {
            a:hover {
                color: #fff;
            }
        }
    }

    &--sticky {
        background: rgba(227, 0, 49, 0.1);
    }

    &__logo {
        margin-bottom: 0.5rem;
        img {
            height: 20px;
        }
    }

    .clinic-capacity {
        margin-bottom: 0.25rem;
    }

    @media (hover: hover) {
        &:not(.clinic_list_item--no-hover):hover {
            background: rgba(227, 0, 49, 0.04);
        }
    }
}
