@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.booking-code-modal {
    h1 {
        hyphens: auto;
        word-break: break-word;
    }

    &__footer {
        margin-top: 3rem;
    }
}

.booking-code-form {
    margin-top: 2rem;

    &__fields {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    &__footer {
        font-size: 1rem;
        margin-top: 1rem;
    }
}
