@import '../../../scss/variables';
@import '../../../scss/mixins';

.handle-cookies {
    h2 {
        font-size: 2.5rem;
        line-height: 115%;
        margin-bottom: 1.5rem;
    }
    &__header {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 1rem;

        a {
            font-size: 1rem;
        }
    }

    &__information {
        font-size: 0.875rem;
        margin: 1rem 0;
        padding-bottom: 1rem;
    }

    .handle-cookies__information ~ .handle-cookies__information {
        border-top: 1px solid #12121226;
    }

    &__information-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 1rem;

        h3 {
            font-size: 1rem;
            line-height: 1.625rem;
            margin-top: 14px;
            margin-bottom: 0.5rem;
        }

        span {
            color: #585858;
        }
    }

    &__deselect-all-button {
        font-size: 1rem;
    }

    &__cookies-list {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        font-size: 1rem;
        margin-top: 1rem;
        list-style-type: none;
    }

    &__spinner {
        display: inline-block;
        width: 2rem;
        height: 2rem;
    }

    &__cookies-list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__no-cookies-text {
        font-size: 1rem;
    }

    &__load-status {
        font-size: 1rem;
        margin-top: 1rem;
    }

    &__load-error {
        color: $primaryColor;

        .btn {
            padding: 0.5rem 1rem;
            margin-left: -1rem;
        }
    }

    &__information-footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;

        .btn {
            margin-right: -1rem;
        }
    }

    &__footer {
        display: flex;
        justify-content: center;
        padding-top: 1rem;
    }
}
