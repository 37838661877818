@import 'src/scss/variables';

// Media of at most the maximum breakpoint width. No query for the largest breakpoint (large).
// Makes the @content apply to the given breakpoint and narrower.
@mixin respond-max-width-breakpoint($name) {
    $max: map-get($breakpoints, $name);
    @if $name != 'large' {
        @media (max-width: $max) {
            @content;
        }
    } @else {
        @content;
    }
}

// Media of at least the minimum breakpoint width.
// No query for the smallest breakpoints (x-small (legacy), small (legacy), compact).
// Makes the @content apply to the given breakpoint and wider.
@mixin respond-min-width-breakpoint($name) {
    $min: map-get($breakpoints, $name);
    @if $name != 'small' and $name != 'x-small' {
        @media (min-width: $min) {
            @content;
        }
    } @else {
        @content;
    }
}
