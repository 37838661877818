@import '../../../scss/mixins';
@import '../../../scss/variables';

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px;
    min-height: 60px;
    position: relative;
    z-index: 1;

    &__logo {
        cursor: pointer;
        width: 107px;
        height: 28px;
    }

    @include respond-max-width-breakpoint(small) {
        padding: 0 1rem;
    }

    &__mobile-menu-button {
        font-size: 1rem;
        font-variation-settings: 'wght' 400;
    }

    &__nav-buttons {
        display: flex;
        gap: 2rem;

        &__nav-button {
            color: $primaryColor;
            font-size: 1rem;
            font-variation-settings: 'wght' 400;

            &--selected {
                font-size: 1rem;
                position: relative;
                font-variation-settings: 'wght' 500;
                color: $primaryAccentColor;
                transition: all 0.3s;

                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -0.5rem;
                    width: 100%;
                    height: 0.2rem;
                    background-color: $primaryAccentColor;
                    border-radius: 2px;
                    box-shadow: 0px 40px 11px rgba(102, 0, 20, 0.01), 0px 25px 10px rgba(102, 0, 20, 0.04),
                        0px 14px 9px rgba(102, 0, 20, 0.15), 0px 6px 6px rgba(102, 0, 20, 0.26),
                        0px 2px 4px rgba(102, 0, 20, 0.29), 0px 0px 0px rgba(102, 0, 20, 0.3);
                    transition: all 0.3s;
                    animation: fadeIn 0.3s ease;
                }
            }
        }

        &__auth-button {
            font-size: 1rem;
            font-variation-settings: 'wght' 400;
            padding: 0.375rem 0.625rem;
        }
    }
}

.skip-nav-container {
    position: absolute;
    transform: translateY(-100%);
    background: $light-pink;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0;

    &:focus-within {
        position: relative;
        display: flex;
        transform: translateY(0);
        transition: transform 0.2s ease;
    }

    &__nav-link {
        color: $primaryColor;
        font-size: 1rem;
        font-weight: 700;
        padding: 0.3rem 0.3rem;
        text-align: center;
        text-decoration: none;

        &:focus-visible {
            position: relative;
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
