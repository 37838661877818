@use 'sass:color';
@import '../../../scss/variables';

.radio-group {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    width: 100%;
    justify-content: space-evenly;
    align-items: stretch;

    @media (max-width: 15.625rem) {
        flex-direction: column;
    }
}

.radio-input {
    flex: 1;
    position: relative;

    &__label {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        gap: 1rem;
        padding: 12px 13px;
        font-size: 1.125rem;
        border-radius: 2.5rem;
        line-height: 150%;
        box-shadow: 0 0 0 1px rgba(18, 18, 18, 0.15);
        user-select: none;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;

        transition: color 0.2s ease, background-color 0.2s ease, box-shadow 0.2s ease;

        &.borderless {
            box-shadow: none;
        }

        &:has(:focus-visible) {
            box-shadow: 0 0 0 3px #121212;
            outline: none;
        }

        // Specific target for firefox, since they don't support ":has" yet
        @supports not selector(input:has(:focus-visible)) {
            &:focus-within {
                box-shadow: 0 0 0 3px #121212;
                outline: none;
            }
        }

        @media (hover: hover) {
            &:hover {
                background: rgba(227, 0, 49, 0.1);
            }
        }
    }

    &__label--active {
        background: $primaryColor;
        color: $secondaryTextColor;
        box-shadow: none;

        &.borderless {
            background-color: transparent;
            color: $primaryTextColor;
        }

        @media (hover: hover) {
            &:hover {
                background: color.scale($primaryColor, $lightness: -10%);
            }
        }

        .radio-input__icon {
            border-color: transparent;
        }
    }

    &__label {
        overflow-wrap: break-word;
        word-break: break-word;
        hyphens: auto;
    }

    &__control {
        min-width: 24px;
        min-height: 24px;
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        appearance: none;
        -webkit-appearance: none;

        &:focus-visible {
            outline: none;
        }

        &::before {
            content: '';
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 50%;
            transform: scale(0);
            transition: 0.2s transform ease-in-out;
            box-shadow: inset 1rem 1rem #fff;
        }
        &:checked {
            box-shadow: inset 0 0 0 3px #fff;
            border: none;
            &.borderless {
                box-shadow: inset 0 0 0 3px #e30031;
                background-color: #e30031;
            }
        }

        &:checked::before {
            transform: scale(1);
        }
    }
}
