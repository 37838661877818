@use 'sass:color';
@import '../../../scss/variables';

.btn {
    font-size: 0.875rem;
    font-weight: 500;
    box-sizing: border-box;
    line-height: 1.125rem;
    padding: 1rem 2rem;
    border-radius: 40px;
    transition: transform 100ms ease;
    position: relative;
    -webkit-tap-highlight-color: transparent;

    @media (hover: hover) {
        &:hover {
            background: #ddd;
        }
    }

    &:active {
        background: #bbb;
        transform: scale(0.95);
    }

    &:focus-visible {
        outline: 2px solid #121212;
    }

    @supports not selector(:focus-visible) {
        &:focus {
            /* Fallback for browsers without :focus-visible support */
            outline: 2px solid #121212;
        }
    }
}

a.btn {
    text-decoration: none;
    display: inline-block;
}

.btn {
    &--filled {
        color: $secondaryTextColor;
        background: $primaryColor;
    }

    &--primary {
        color: $secondaryTextColor;
        background: $primaryColor;

        @media (hover: hover) {
            &:hover {
                background: color.scale($primaryColor, $lightness: -20%);
            }
        }

        &:active {
            background: color.scale($primaryColor, $lightness: -30%);
        }

        &:disabled {
            background: rgba(18, 18, 18, 0.05);
            color: rgba(18, 18, 18, 0.3);
            cursor: auto;
        }
    }

    &--text {
        background: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        font-family: 'RubikVariable';
        font-variation-settings: 'wght' 500;
        text-decoration: underline;

        &:active {
            background: transparent;
            transform: scale(0.95);
        }

        border-radius: 0px;
        outline-offset: 2px;
    }

    &--lighter {
        color: $primaryColor;
        background: $lighterPrimary;

        @media (hover: hover) {
            &:hover {
                background: darken($lighterPrimary, 5);
            }
        }

        &:active {
            background: darken($lighterPrimary, 10);
        }
    }

    &--secondary {
        color: $secondaryTextColor;
        background: $secondaryColor;

        @media (hover: hover) {
            &:hover {
                background: darken($secondaryColor, 5);
            }
        }

        &:active {
            background: darken($secondaryColor, 10);
        }
    }

    &--tertiary {
        color: $primaryColor;
        background: $tertiaryColor;
        box-shadow: 0 0 0 1px rgba(18, 18, 18, 0.15);

        @media (hover: hover) {
            &:hover {
                background: rgba(227, 0, 49, 0.1);
            }
        }

        &:active {
            background: rgba(227, 0, 49, 0.2);
        }
    }

    &--outlined {
        border: 1px solid rgba(0, 0, 0, 0.15);
        background: transparent;

        @media (hover: hover) {
            &:hover {
                background: rgba(255, 255, 255, 0.15);
            }
        }

        &:active {
            background: rgba(255, 255, 255, 0.25);
        }
    }

    &--primary.btn--outlined {
        color: $primaryColor;

        @media (hover: hover) {
            &:hover {
                background: rgba(227, 0, 49, 0.1);
            }
        }
    }

    &--secondary.btn--outlined {
        color: $primaryTextColor;
    }

    &--tertiary.btn--outlined {
        color: $secondaryTextColor;
        border: 1px solid rgba(255, 255, 255, 0.4);
    }
    &--on-dark.btn--outlined {
        border: 1px solid rgba(255, 255, 255, 0.4);
    }

    &--primary.btn--text {
        color: $primaryColor;

        @media (hover: hover) {
            &:hover {
                background: none;
                color: darken($primaryColor, 10);
            }
        }
    }

    &--secondary.btn--text {
        color: $primaryTextColor;
    }

    &--tertiary.btn--text {
        color: $secondaryTextColor;
    }

    &--on-dark.btn--text {
        border: 1px solid rgba(255, 255, 255, 0.4);
    }

    &--on-dark {
        &:focus-visible {
            outline: none;

            &::before {
                border: 3px solid #fff;
            }
        }

        @supports not selector(:focus-visible) {
            &:focus {
                /* Fallback for browsers without :focus-visible support */
                outline: 3px solid #fff;
            }
        }
    }

    &--big {
        font-size: 1.125rem;
        padding: 23px 32px;
    }

    &--thin {
        line-height: 1.5;
        padding: 6px 10px;
    }

    &--wide {
        width: 100%;
    }
}
