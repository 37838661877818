@import '../../scss/variables';
@import '../../scss/mixins';
@import '../../scss/animations';

.account-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    word-break: break-word;
    hyphens: auto;
    max-width: 31.25rem;
    height: 100%;

    &__heading {
        display: flex;
        flex-wrap: wrap;
        text-wrap: balance;
        font-size: 2.5rem;
        font-variation-settings: 'wght' 700;
        line-height: 1.15;
        margin-bottom: 2rem;
    }

    &__spinner {
        padding-top: 5rem;
        grid-column: 1 / -1;
        justify-self: center;
        align-self: center;
        width: 2.5rem;
    }

    &__inputs {
        display: grid;
        gap: 1.5rem;
        margin-bottom: 1rem;

        &--editing {
            gap: 1rem;
            margin-bottom: 2rem;
        }

        &--read-only-label {
            font-size: 0.875rem;
        }

        &--read-only-text {
            font-size: 1.25rem;
        }
    }

    &__notification-options {
        display: grid;
        gap: 1rem;
        margin-bottom: 2rem;
    }

    &__buttons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        @include respond-max-width-breakpoint(compact) {
            grid-template-columns: 1fr;
        }
        gap: 1rem;
        margin-bottom: 2rem;

        &__button {
            font-size: 1rem;
        }

        &--loading {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;

            &__spinner {
                width: 1rem;
                height: 1rem;
            }
        }
    }

    &__divider {
        width: 100%;
        position: relative;
        border-top: 1px solid #e0e0e0;
        @include respond-min-width-breakpoint(compact) {
            display: none;
        }
    }

    &__bottom-links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;

        &__remove-button {
            justify-self: start;
            font-size: 1rem;
            padding: 0;
        }
    }
}
