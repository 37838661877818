@import '../../scss/variables';
@import '../../scss/mixins';

.about-container {
    margin: 0 2rem;
    max-width: 60em;
    @include respond-max-width-breakpoint(compact) {
        margin: 0 1rem;
    }

    main {
        margin: 2rem 0;
        @include respond-max-width-breakpoint(medium) {
            word-break: break-word;
            hyphens: auto;
        }
    }

    &__intro {
        margin-top: 2rem;
    }

    &__heading {
        font-size: 2.5rem;
        padding-bottom: 1.5rem;
    }

    &__sub-heading {
        font-size: 1.125rem;
        margin-top: 1.5rem;
    }

    &__paragraph {
        margin-top: 1.5rem;
    }

    &__bottom-links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        margin-top: 2rem;
    }
}
