.bookings-list {
    .bookings {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 3.125rem;
        margin: 3.125rem 0;

        &__no-bookings {
            font-size: 1.125rem;
        }

        .booking-item:only-of-type,
        .booking_skeleton:only-of-type {
            margin-top: 0;
        }
    }
}
