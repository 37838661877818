@use 'sass:color';
@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.container {
    .booking-time-content {
        margin-right: 0;
        width: 100%;
        word-break: normal;
        max-width: 100%;
        min-height: 90vh;

        .error_modal {
            position: fixed;
        }

        &__mobile-nav {
            display: none;

            @include respond-max-width-breakpoint(medium) {
                display: flex;
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 1;
                background-color: #fff;
                padding: 0.5rem 1rem;
                border-radius: 24px 24px 0 0;
                background: #fef5f7;

                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                button {
                    font-size: 1rem;
                    padding: 16px 32px;
                }

                .timeslot__spinner {
                    width: 28px;
                    height: 28px;
                    display: flex;
                    justify-content: center;
                    margin-right: 36px;
                    margin: 11px 36px 11px 0;
                }
            }
        }
    }
}
