@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.wrap-html {
    white-space: pre-wrap;
    font-size: 1.125rem;
    line-height: 1.5;
    > button.primary {
        text-decoration: underline;
        font-variation-settings: 'wght' 500;
    }
}

.aside-content {
    grid-area: aside;
    justify-self: end;
    padding: 0;

    @include respond-max-width-breakpoint(medium) {
        display: flex;
        flex-direction: column;
        max-width: 100%;
    }
    &__container {
        background-color: rgba(227, 0, 49, 0.04);
        padding: 2rem 0 0;
        border-radius: 50px;
        position: sticky;
        top: 4.75rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &--initial {
            position: static;
            min-width: 250px;
            max-width: 350px;
            width: 100%;
            p {
                font-size: 1rem;
            }

            @include respond-max-width-breakpoint(medium) {
                max-width: 100%;
            }

            @media (max-width: 15.625rem) {
                min-width: auto;
            }
        }

        section {
            margin-bottom: 2.5rem;

            &:last-child {
                margin-bottom: 0;
            }
            &.contact-us:last-child {
                padding-bottom: 2rem;
            }
        }
        .aside-padding {
            padding: 0 1.5rem;
        }
        h2 {
            margin-bottom: 0;
        }
        .links {
            display: flex;
            padding: 0 0.5rem;
            flex-direction: column;
            a {
                display: flex;
                gap: 1rem;
                align-items: center;
                padding: 1rem;
                border-radius: 28px;
                &:hover {
                    background-color: rgba($primaryAccentColor, 0.1);
                }

                svg {
                    min-width: 24px;
                }
            }
        }
        .aside-map {
            width: 100%;
            height: 200px;

            .map_container {
                width: 100%;
                height: 100%;
                border-bottom-left-radius: 50px;
                border-bottom-right-radius: 50px;
                pointer-events: none;

                .leaflet-control-container {
                    display: none;
                }
                .leaflet-marker-icon {
                    pointer-events: inherit;
                }
            }
        }
    }
    &__loading {
        background-color: rgba(227, 0, 49, 0.04);
        height: 85vh;
        width: 100%;
        border-radius: 50px;
    }
}
