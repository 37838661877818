@import '../../../scss/variables';

.search-header-container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    &__header {
        margin-bottom: 0;
    }
}
