//
// Breakpoints
//

$breakpoints: (
    large: 62.5625rem,
    expanded: 62.5rem,
    medium: 52.5rem,
    compact: 37.5rem,
    // backwards compatibility (small), should be removed
    small: 40rem,
    // backwards compatibility (x-small), should be removed
    x-small: 23.4375rem
);

//
// Legacy Colors
//

$primaryColor: rgba(227, 0, 49, 1);
$secondaryColor: #121212;
$tertiaryColor: #fff;
$primaryAccentColor: #e30031;
$lighterPrimary: #e300310a;
$primaryTextColor: #121212;
$primaryBackgroundColor: #fff;
$secondaryTextColor: #fff;
$secondaryBackgroundColor: #121212;
$placeholderTextColor: #9e9e9e;

//
// Colors
//

$black: #121212;
$light-pink: #fef5f7;
$pink: #fbdce3;
$red: #e30031;
$gray300: #d4d4d4;
$gray600: #757575;
$gray700: #616161;
